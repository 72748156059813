import * as actions from '../../actions/types';

const initialState = {
  termsContent: undefined,
  privacyContent: undefined,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_CONTENT_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_TERMS_CONTENT:
      return {
        ...state,
        termsContent: action.payload
      };
    case actions.SET_PRIVACY_CONTENT:
      return {
        ...state,
        privacyContent: action.payload
      };
    default:
      return state;
  }
}
