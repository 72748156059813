import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { clearResponseMessage } from '../../../actions/messageActions';
import Status from '../../../components/StatusBox/Status';
import { forgotPassword } from '../../../services/authService';
import AuthLayout from '../AuthLayout';
import classNames from 'classnames';

const AuthBoxDetailWrapper = styled.div`
  width: 100%;
  display: block;
  p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      color: #fe715b;
      cursor: pointer;
    }
  }
`;
const AuthBoxDetail = styled.div`
  text-align: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
  .h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: -1.08px;
    color: #ffffff;
    margin-bottom: 8px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthForm = styled.form`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const FormColFull = styled.div`
  display: block;
  width: 100%;
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 0;
  padding: 14px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  ::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.3);
  }
`;
const ContentHead = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  margin-top: 20px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const ButtonBox = styled.div`
  text-align: center;
  margin-bottom: 60px;
  margin-top: 40px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;
const Button = styled.button`
  background: #ff715b;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
  border: 0;
  border-radius: 4px;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleEmailChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const obj = {
      email: String(email).trim()
    };
    await this.props.forgotPassword(obj);
  };

  onLoginClick = () => {
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { email } = this.state;

    return (
      <>
        <AuthLayout>
          <AuthBoxDetailWrapper>
            <AuthBoxDetail>
              <h2 className='h2'>Forgot Password</h2>
              <span>
                Enter your email address below and we’ll send you instructions on how to change your password.
              </span>
            </AuthBoxDetail>
            <AuthForm onSubmit={this.onSubmit}>
              <FormColFull>
                <ContentHead first>Email</ContentHead>
                <Input
                  type='email'
                  maxLength='256'
                  name='Email'
                  placeholder='enter your email address'
                  autocomplete='off'
                  required=''
                  value={email}
                  onChange={this.handleEmailChange}
                />
              </FormColFull>
              {this.renderSuccess()}
              {this.renderErrors()}
              <ButtonBox className={classNames("btn-save-loading", { 'loading': loading })}>
                <Button type='submit' disabled={loading} className="btn base_btn">
                  Send
                </Button>
              </ButtonBox>
            </AuthForm>
            <p>
              Go back to{' '}
              <a disabled={loading} onClick={this.onLoginClick}>
                Log In
              </a>
            </p>
          </AuthBoxDetailWrapper>
        </AuthLayout>
      </>
    );
  }
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  forgotPassword
})(ForgotPassword);
