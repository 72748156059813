import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../../actions/messageActions';
import { getContent } from '../../../services/contentService';
import { TYPE_TERMS } from '../../../global/constant';
import ReactMarkdown from 'react-markdown';

class TermsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.clearResponseMessage();
    this.LoadData();
  }

  LoadData = async () => {
    const { termsContent } = this.props.content;
    if (!termsContent) {
      await this.props.getContent(TYPE_TERMS);
    }
  };

  render() {
    const { termsContent } = this.props.content;
    let { content = '' } = termsContent || {};
    content = content.replace(/(?:\r\n|\r|\n)/g, '\n  \n').replace(/\n/gi, '\n &nbsp;');
    return (
      <div className='termsmodalbox'>
        <ReactMarkdown className='termsparagraph line-break' children={content} />
      </div>
    );
  }
}

TermsModal.propTypes = {
  content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  content: state.content
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getContent
})(TermsModal);
