import * as actions from '../../actions/types';

const initialState = {
  watchListEvents: undefined,
  loading: false,
  watchList: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_WATCHLIST_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_WATCHLIST:
      return {
        ...state,
        watchList: action.payload
      };
    case actions.SET_WATCHLIST_EVENTS:
      return {
        ...state,
        watchListEvents: action.payload
      };
    default:
      return state;
  }
}
