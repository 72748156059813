import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import style from '../../assets/scss/style.scss';
import styled, { css } from 'styled-components';
import Button from '../../components/Button/Button';
import data from '../../data/raw';
import Dropdown from '../../components/DropDown/DropDown';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { TYPE_ALL } from '../../global/constant';
import Status from '../../components/StatusBox/Status';
import Dropzone from 'react-dropzone';
import { isEmpty } from '../../global/Helpers';
import Iconupload from '../../assets/images/upload.svg';
import classNames from 'classnames';
import { getEventList, createEvent, uploadImage, fetchIPLocation } from '../../services/eventService';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';

const Contentbox = styled.div``;
const Form = styled.form``;
const ContentBody = styled.div``;
const ContentHead = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.48px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const FormHead = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 6px;
  &.title {
    margin-top: 12px;
  }
`;
const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px -8px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    display: block;
    margin: 20px -8px 0 -8px;
  }
  &.formgroup {
    display: block;
  }
  h4 {
    padding: 0;
    margin-bottom: 6px;
  }
`;
const FormColFull = styled.div`
  max-width: 100%;
  flex: 0 1 100%;
  padding: 0px 8px;
  &.formcol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0;
  }
  .containersm {
    background: rgba(255, 255, 255, 0.05);
    padding: 6px 6px 6px 10px;
    width: 100%;
    max-width: 82.4%;
    border-radius: 5px;
  }
  &.upload {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 200;
    }
  }
  .btnchoose {
    border: 0;
    margin: 10px 0;
    background: #484955;
    border-radius: 4px;
    padding: 10px 12px;
    color: #fff;
  }
  .uploadimg {
    display: block;
    margin: auto;
  }
`;
const FormColHalf = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0px 8px;
  margin-bottom: 15px;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  border: 0;
  padding: 12px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;
const Textarea = styled.textarea`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  border: 0;
  padding: 12px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  outline: none;
  width: 100%;
  resize: vertical;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;
const DropZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    padding: 10px 12px;
    background: #484955;
    border-radius: 4px;
    border: 0;
    color: #fff;
  }
  .btnupload {
    background: #ff715b;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    padding: 12px 17px;
    border: 0;
    color: #fff;
  }
`;
const DropZoneDiv = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  p {
    margin-left: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50%;
  }
`;

const modules = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
    [{ list: 'ordered' }],
    [{ list: 'bullet' }],
    ['clean']
  ]
};

class AddEventModal extends Component {
  constructor(props) {
    super(props);
    const authUser = props.auth.user || {};
    let date = new Date();
    date.setMinutes(Math.round(date.getMinutes() / 10) * 10 + 10);
    const startDate = date;
    const endDate = moment(startDate).add(1, 'day').toDate();

    this.state = {
      publisher: authUser.displayName || '',
      name: authUser.displayName || '',
      email: authUser.email || '',
      startTime: startDate,
      endTime: endDate,
      eventName: '',
      eventDesc: '',
      eventUrl: '',
      subjectId: '',
      channelId: 'all',
      eventTypeId: '',
      country: 'US',
      language: 'en',
      facebookUrl: '',
      twitterUrl: '',
      twitchUrl: '',
      linkedInUrl: '',
      publisherLogo: '',
      publisherThumbnail: '',
      publisherLogoFile: '',
      times: this.getClockTimeDurations()
    };
    this.publisherLogoRef = React.createRef();
    this.publisherThumbnailRef = React.createRef();
  }

  getClockTimeDurations = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 6; j++) {
        const value = `${i}`.padStart(2, '0') + ':' + `${j}`.padEnd(2, '0');
        times.push({ value, text: value });
      }
    }
    return times;
  };

  componentDidMount() {
    this.props.clearResponseMessage();
    this.setDefaultCountry();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  setDefaultCountry = async () => {
    const result = await this.props.fetchIPLocation();
    if (result) {
      this.setState({ country: result.countryCode || 'US' });
    }
  };

  onPublisherChange = (e) => {
    const publisher = e.target.value;
    this.setState({ publisher });
  };

  onNameChange = (e) => {
    const name = e.target.value;
    this.setState({ name });
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  onStartDateChange = (e) => {
    const startTime = e.target.value;
    this.setState({ startTime });
  };

  onEndDateChange = (e) => {
    const endTime = e.target.value;
    this.setState({ endTime });
  };

  onStartTimeChange = (value) => {
    const { startTime } = this.state;
    const dateString = moment(startTime).format('DD-MMM-YYYY') + ' ' + value;
    const newDateTime = new Date(dateString);
    this.setState({ startTime: newDateTime });
  };

  onEndTimeChange = (value) => {
    const { endTime } = this.state;
    const dateString = moment(endTime).format('DD-MMM-YYYY') + ' ' + value;
    const newDateTime = new Date(dateString);
    this.setState({ endTime: newDateTime });
  };

  onEventNameChange = (e) => {
    const eventName = e.target.value;
    this.setState({ eventName });
  };

  onEventDateChange = (e) => {
    const eventDate = e.target.value;
    this.setState({ eventDate });
  };

  onEventDescChange = (value) => {
    const eventDesc = value;
    this.setState({ eventDesc });
  };

  onEventUrlChange = (e) => {
    const eventUrl = e.target.value;
    this.setState({ eventUrl });
  };

  onSubjectChange = (value) => {
    this.setState({ subjectId: value });
  };

  onChannelChange = (value) => {
    this.setState({ channelId: value });
  };

  onEventTypeChange = (value) => {
    this.setState({ eventTypeId: value });
  };

  onCountryChange = (country) => {
    this.setState({ country });
  };

  onLanguageChange = (language) => {
    this.setState({ language });
  };

  onFacebookUrlChange = (e) => {
    const facebookUrl = e.target.value;
    this.setState({ facebookUrl });
  };

  onTwitterUrlChange = (e) => {
    const twitterUrl = e.target.value;
    this.setState({ twitterUrl });
  };

  onTwitchUrlChange = (e) => {
    const twitchUrl = e.target.value;
    this.setState({ twitchUrl });
  };

  onLinkedInUrlChange = (e) => {
    const linkedInUrl = e.target.value;
    this.setState({ linkedInUrl });
  };

  openPublisherLogoDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (this.publisherLogoRef.current) {
      this.publisherLogoRef.current.open();
    }
  };

  openPublisherThumbnailDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (this.publisherThumbnailRef.current) {
      this.publisherThumbnailRef.current.open();
    }
  };

  onDropPublisherLogo = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = 'Please submit valid file type';
      this.props.setErrorMessage(message);
    } else {
      const file = accepted[0];
      this.props.setErrorMessage('');
      this.setState({ publisherLogoFile: file });
    }
  };

  onChangePublisherLogo = (event) => {
    this.setState({ publisherLogoFile: event.target.files[0] });
    this.readFile(event.target.files[0], 'publisherLogo');
  };

  onUploadPublisherLogo = () => {
    const { publisherLogoFile } = this.state;
    this.readFile(publisherLogoFile, 'publisherLogo');
  };

  onDropPublisherThumbnail = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = 'Please submit valid file type';
      this.props.setErrorMessage(message);
    } else {
      const file = accepted[0];
      this.props.setErrorMessage('');
      this.readFile(file, 'publisherThumbnail');
    }
  };

  readFile = (file, key) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      let ImageData = reader.result;
      if (ImageData) {
        const splitData = ImageData.split(',');
        if (splitData && splitData.length > 1) ImageData = splitData[1];
        const upload = {
          name: file.name,
          contentType: file.type,
          file: ImageData
        };
        const result = await this.props.uploadImage(upload);
        if (result) {
          this.setState({ [key]: result.url });
        }
      }
    };
  };

  handleFormSubmission = async (e) => {
    e.preventDefault();
    const {
      publisher,
      name,
      email,
      startTime,
      endTime,
      eventName,
      eventDesc,
      eventUrl,
      subjectId,
      channelId,
      eventTypeId,
      country,
      language,
      facebookUrl,
      twitterUrl,
      twitchUrl,
      linkedInUrl,
      publisherLogo,
      publisherThumbnail
    } = this.state;

    if (moment(startTime).isBefore(new Date())) {
      this.props.setErrorMessage('Start date/time must be future date time');
      return;
    } else if (moment(endTime).isBefore(new Date())) {
      this.props.setErrorMessage('End date/time must be future date time');
      return;
    } else if (moment(endTime).isBefore(endTime)) {
      this.props.setErrorMessage('End date/time must be greater than start date time');
      return;
    }

    const eventObj = {
      publisher,
      name,
      email,
      startTime,
      endTime,
      eventName,
      eventDesc,
      eventUrl,
      subjectId,
      channelId,
      eventTypeId,
      country,
      language,
      facebookUrl,
      twitterUrl,
      twitchUrl,
      linkedInUrl,
      publisherLogo,
      publisherThumbnail
    };

    const result = await this.props.createEvent(eventObj);
    if (result) {
      await this.props.getEventList();
      if (this.props.onEventAdded) this.props.onEventAdded();
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  render() {
    const {
      publisher,
      name,
      email,
      startTime,
      endTime,
      eventName,
      eventDesc,
      eventUrl,
      subjectId,
      channelId,
      eventTypeId,
      country,
      language,
      facebookUrl,
      twitterUrl,
      twitchUrl,
      linkedInUrl,
      publisherLogo,
      publisherThumbnail,
      times
    } = this.state;
    const { countries, languages } = data;
    let { subjects = [], channels = [], eventTypes = [] } = this.props.misc;
    // filter by id all; as can't select all
    subjects = subjects.filter((x) => x.value !== TYPE_ALL);
    channels = channels.filter((x) => x.value !== TYPE_ALL);
    const { loading } = this.props.home;
    return (
      <>
        <Contentbox>
          <Form onSubmit={this.handleFormSubmission}>
            <ContentBody>
              <ContentHead first>Your Details</ContentHead>
              <FormGroup>
                <FormColHalf>
                  <FormHead first>Full Name</FormHead>
                  <Input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='enter your name'
                    value={name}
                    onChange={this.onNameChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>E-Mail</FormHead>
                  <Input
                    type='text'
                    id='email'
                    name='email'
                    placeholder='enter your email address'
                    value={email}
                    onChange={this.onEmailChange}
                  />
                </FormColHalf>
              </FormGroup>
              <FormGroup>
                <FormColFull>
                  <FormHead first>Publisher name</FormHead>
                  <Input
                    type='text'
                    id='publisher'
                    name='publisher'
                    placeholder='enter your publisher name'
                    value={publisher}
                    onChange={this.onPublisherChange}
                  />
                </FormColFull>
              </FormGroup>

              <ContentHead>Event Details</ContentHead>
              <FormGroup>
                <FormColFull>
                  <FormHead first>Event Name</FormHead>
                  <Input
                    type='text'
                    id='eventName'
                    name='eventName'
                    placeholder='enter your event name'
                    value={eventName}
                    onChange={this.onEventNameChange}
                  />
                </FormColFull>
              </FormGroup>
              <FormGroup>
                <FormColHalf>
                  <FormHead first>Start Date / Time</FormHead>
                  <div className='datetimewrapper'>
                    <DatePicker
                      id='startTime'
                      name='startTime'
                      format='MM/dd/yyyy'
                      min={new Date()}
                      value={startTime}
                      onChange={this.onStartDateChange}
                    />
                    <div className='Datebox'>
                      <Dropdown
                        // defaultItem={'select start time'}
                        property={'startTime'}
                        value={startTime ? moment(startTime).format('HH:mm') : ''}
                        dataArray={times}
                        onChange={this.onStartTimeChange}
                        textField={'text'}
                        dataItemKey={'value'}
                      />
                    </div>
                  </div>
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>End Date / Time</FormHead>
                  <div className='datetimewrapper'>
                    <DatePicker
                      id='endDate'
                      name='endDate'
                      format='MM/dd/yyyy'
                      min={startTime || new Date()}
                      value={endTime}
                      onChange={this.onEndDateChange}
                    />
                    <div className='Datebox'>
                      <Dropdown
                        // defaultItem={'select end time'}
                        property={'endTime'}
                        value={endTime ? moment(endTime).format('HH:mm') : ''}
                        dataArray={times}
                        onChange={this.onEndTimeChange}
                        textField={'text'}
                        dataItemKey={'value'}
                      />
                    </div>
                  </div>
                </FormColHalf>
              </FormGroup>
              <FormGroup>
                <FormColFull>
                  <FormHead first>Event Description</FormHead>
                  <ReactQuill
                    theme='snow'
                    modules={modules}
                    placeholder={'enter event description'}
                    value={eventDesc}
                    className='txt-editor'
                    onChange={this.onEventDescChange}
                  />
                </FormColFull>
              </FormGroup>
              <FormGroup>
                <FormColFull>
                  <FormHead first>Event URL</FormHead>
                  <Input
                    type='text'
                    id='eventUrl'
                    name='eventUrl'
                    placeholder='enter your event URL'
                    value={eventUrl}
                    onChange={this.onEventUrlChange}
                  />
                </FormColFull>
              </FormGroup>

              <ContentHead>Event Filters</ContentHead>
              <FormGroup>
                <FormColHalf>
                  <FormHead first>Event Subjects</FormHead>
                  <Dropdown
                    defaultItem={'select your subject'}
                    property={'subjectId'}
                    value={subjectId}
                    dataArray={subjects}
                    onChange={this.onSubjectChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Event Channels</FormHead>
                  <Dropdown
                    defaultItem={'select event channel'}
                    property={'channelId'}
                    value={channelId}
                    dataArray={channels}
                    onChange={this.onChannelChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Event Type</FormHead>
                  <Dropdown
                    defaultItem={'select event type'}
                    property={'eventTypeId'}
                    value={eventTypeId}
                    dataArray={eventTypes}
                    onChange={this.onEventTypeChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Country</FormHead>
                  <Dropdown
                    defaultItem={'select your country'}
                    property={'country'}
                    value={country}
                    dataArray={countries}
                    onChange={this.onCountryChange}
                    textField={'text'}
                    dataItemKey={'value'}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Language</FormHead>
                  <Dropdown
                    defaultItem={'select your language'}
                    property={'language'}
                    value={language}
                    dataArray={languages}
                    onChange={this.onLanguageChange}
                    textField={'text'}
                    dataItemKey={'value'}
                  />
                </FormColHalf>
              </FormGroup>

              <ContentHead>Social Details</ContentHead>
              <FormGroup>
                <FormColHalf>
                  <FormHead first>Facebook</FormHead>
                  <Input
                    type='text'
                    id='facebook'
                    name='facebook'
                    placeholder='facebook URL'
                    value={facebookUrl}
                    onChange={this.onFacebookUrlChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Twitter</FormHead>
                  <Input
                    type='text'
                    id='twitter'
                    name='twitter'
                    placeholder='twitter URL'
                    value={twitterUrl}
                    onChange={this.onTwitterUrlChange}
                  />
                </FormColHalf>
              </FormGroup>
              <FormGroup>
                <FormColHalf>
                  <FormHead first>Twitch</FormHead>
                  <Input
                    type='text'
                    id='twitch'
                    name='twitch'
                    placeholder='twitch URL'
                    value={twitchUrl}
                    onChange={this.onTwitchUrlChange}
                  />
                </FormColHalf>
                <FormColHalf>
                  <FormHead first>Linkedin</FormHead>
                  <Input
                    type='text'
                    id='linkedin'
                    name='linkedin'
                    placeholder='linkedin URL'
                    value={linkedInUrl}
                    onChange={this.onLinkedInUrlChange}
                  />
                </FormColHalf>
              </FormGroup>

              <ContentHead>Event Graphics</ContentHead>
              <FormHead className='title' first>
                Publisher Logo
              </FormHead>
              <FormColFull className='formcol'>
                <div className='upload_preview'>{publisherLogo && <img src={publisherLogo} alt='logo' />}</div>
                <Dropzone
                  ref={this.publisherLogoRef}
                  noClick
                  noKeyboard
                  accept='image/*'
                  multiple={false}
                  onDrop={(accepted, rejected) => this.onDropPublisherLogo(accepted, rejected)}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => {
                    return (
                      <div className='container containersm'>
                        <DropZone {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <DropZoneDiv>
                            {/* <button className='btn' type="button" onClick={this.openPublisherLogoDialog}>Choose image to upload</button> */}
                            <label htmlFor='imageLogo' className='btn'>
                              Choose image to upload
                            </label>
                            <input
                              id='imageLogo'
                              style={{ display: 'none' }}
                              type='file'
                              onChange={this.onChangePublisherLogo}
                              accept='image/*'
                            />
                            {acceptedFiles && acceptedFiles.length > 0 && <p>{acceptedFiles[0].name}</p>}
                          </DropZoneDiv>
                          {/* <div className={classNames("btn-save-loading", { 'loading': loading })}>
                            <button className="btnupload btn base_btn" type={"button"} onClick={this.onUploadPublisherLogo}>Upload your logo</button>
                          </div> */}
                        </DropZone>
                      </div>
                    );
                  }}
                </Dropzone>
              </FormColFull>
              <FormGroup className='formgroup'>
                <FormHead first>Publisher Thumbnail</FormHead>
                <FormColFull className='upload'>
                  <Dropzone
                    ref={this.publisherThumbnailRef}
                    noClick
                    noKeyboard
                    accept='image/*'
                    multiple={false}
                    onDrop={(accepted, rejected) => this.onDropPublisherThumbnail(accepted, rejected)}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                      return (
                        <div className='container'>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            {isEmpty(publisherThumbnail) ? (
                              <div>
                                <img src={Iconupload} className='uploadimg' alt='uploaded' />
                                <button className='btnchoose' type='button' onClick={this.openPublisherThumbnailDialog}>
                                  Choose image to upload
                                </button>
                                <p>or drag & drop them here</p>
                              </div>
                            ) : (
                              <div className='upload_preview upload_box'>
                                <img src={publisherThumbnail || ''} alt='Upload Thumbnail' />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                </FormColFull>
              </FormGroup>
            </ContentBody>
            {this.renderErrors()}
            <div className={classNames('btn-save-loading', { loading: loading })}>
              <Button type='submit' title='Submit' isPrimary={true} className='btn base_btn' />
            </div>
          </Form>
        </Contentbox>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  misc: state.misc,
  home: state.home
});

export default connect(mapStateToProps, {
  getEventList,
  createEvent,
  clearResponseMessage,
  setErrorMessage,
  uploadImage,
  fetchIPLocation
})(AddEventModal);
