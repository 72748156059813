import * as actions from './types';

/**
 * @desc Set watchList loader
 */
export const setWatchListLoader = (payload) => {
  return {
    type: actions.SET_WATCHLIST_LOADER,
    payload: payload
  };
};

/**
 * @desc Set watchList events
 */
export const setWatchListEvents = (payload) => {
  return {
    type: actions.SET_WATCHLIST_EVENTS,
    payload: payload
  };
};

/**
 * @desc Set watch list
 */
export const setWatchList = (payload) => {
  return {
    type: actions.SET_WATCHLIST,
    payload: payload
  };
};