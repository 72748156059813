import * as actions from './types';

/**
 * @desc Set misc loader
 */
export const setMiscLoader = (payload) => {
  return {
    type: actions.SET_MISC_LOADER,
    payload: payload
  };
};

/**
 * @desc Set misc subject list
 */
export const setSubjectList = (payload) => {
  return {
    type: actions.SET_MISC_SUBJECTS,
    payload: payload
  };
};

/**
 * @desc Set misc channel list
 */
export const setChannelList = (payload) => {
  return {
    type: actions.SET_MISC_CHANNELS,
    payload: payload
  };
};

/**
 * @desc Set misc event type list
 */
export const setEventTypeList = (payload) => {
  return {
    type: actions.SET_MISC_EVENTTYPES,
    payload: payload
  };
};
