//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';

//Auth
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

//Home
export const SET_HOME_LOADER = 'SET_HOME_LOADER';
export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const SET_DISPLAY_EVENT_LIST = 'SET_DISPLAY_EVENT_LIST';
export const SET_EVENT_DETAIL = 'SET_EVENT_DETAIL';

//WatchList
export const SET_WATCHLIST_LOADER = 'SET_WATCHLIST_LOADER';
export const SET_WATCHLIST_EVENTS = 'SET_WATCHLIST_EVENTS';
export const SET_WATCHLIST = 'SET_WATCHLIST';

//Misc
export const SET_MISC_LOADER = 'SET_MISC_LOADER';
export const SET_MISC_SUBJECTS = 'SET_MISC_SUBJECTS';
export const SET_MISC_CHANNELS = 'SET_MISC_CHANNELS';
export const SET_MISC_EVENTTYPES = 'SET_MISC_EVENTTYPES';

//Content
export const SET_CONTENT_LOADER = 'SET_CONTENT_LOADER';
export const SET_TERMS_CONTENT = 'SET_TERMS_CONTENT';
export const SET_PRIVACY_CONTENT = 'SET_PRIVACY_CONTENT';
