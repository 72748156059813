import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../../actions/messageActions';
import { getContent } from '../../../services/contentService';
import ReactMarkdown from 'react-markdown';
import { TYPE_PRIVACY } from '../../../global/constant';

class PrivacyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.clearResponseMessage();
    this.LoadData();
  }

  LoadData = async () => {
    const { privacyContent } = this.props.content;
    if (!privacyContent) {
      await this.props.getContent(TYPE_PRIVACY);
    }
  };

  render() {
    const { privacyContent } = this.props.content;
    let { content = '' } = privacyContent || {};
    content = content.replace(/(?:\r\n|\r|\n)/g, '\n  \n').replace(/\n/gi, '\n &nbsp;');
    return (
      <div className='termsmodalbox'>
        <ReactMarkdown className='termsparagraph' children={content} />
      </div>
    );
  }
}

PrivacyModal.propTypes = {
  content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  content: state.content
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getContent
})(PrivacyModal);
