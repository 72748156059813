import * as actions from './types';

/**
 * @desc Set home loader
 */
export const setHomeLoader = (payload) => {
  return {
    type: actions.SET_HOME_LOADER,
    payload: payload
  };
};

/**
 * @desc Set event list
 */
export const setEventList = (payload) => {
  return {
    type: actions.SET_EVENT_LIST,
    payload: payload
  };
};

/**
 * @desc Set event Detail
 */
export const setEventDetail = (payload) => {
  return {
    type: actions.SET_EVENT_DETAIL,
    payload: payload
  };
};
