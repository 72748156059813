import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../assets/universal/themeColor';

const PrimaryButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  user-select: none;
  text-transform: capitalize;
  border: 1px solid ${theme.colors.Primary_btn_border_clr};
  background: ${theme.colors.Primary_btn_bg_clr};
  color: ${theme.colors.Primary_btn_txt_clr};
  transition: 0.3s all ease;
  &:disabled {
    opacity: 0.6;
  }
  &:hover{ 
    box-shadow: inset 0px 0px 20px 20px ${theme.colors.Primary_btn_shadow_clr};
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
`;
const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid ${theme.colors.Secondary_btn_border_clr};
  background: ${theme.colors.Secondary_btn_bg_clr};
  color: ${theme.colors.Secondary_btn_txt_clr};
`;

class Button extends Component {
  render() {
    const { title, type, disabled, isPrimary, onClick } = this.props;
    return (
      <>
        {isPrimary === true && (
          <PrimaryButton {...this.props} type={type} disabled={disabled} onClick={onClick}>
            {title}
          </PrimaryButton>
        )}
        {isPrimary === false && (
          <SecondaryButton {...this.props} type={type} disabled={disabled} onClick={onClick}>
            {title}
          </SecondaryButton>
        )}
      </>
    );
  }
}

export default Button;
