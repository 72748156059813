import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import globalStyle from '../../assets/universal/globalStyles';
import styled from 'styled-components';
import { fetchIPLocation, getEventList, uploadImage } from '../../services/eventService';
import { removeWhiteSpaceRegex } from '../../global/Helpers';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';
import Loading from '../../components/Loading/Loading';
import ListItem from '../Home/ListItem';
import Footer from '../../components/Footer/Footer';
import { onToggleWatchListEvent } from '../../services/watchListService';

const Container = globalStyle.Container;
const BolshoiBoxWrapper = styled.div`
  padding: 80px 0 80px 0;
  text-align: center;
  @media only screen and (max-width: 1279px) {
    padding: 60px 0;
  }
`;
const BolshoiBox = styled.div`
  span {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -1.08px;
    text-transform: capitalize;
    color: #ffffff;
    margin: 20px 0 15px 0;
    display: block;
    @media only screen and (max-width: 1279px) {
      font-size: 29px;
      line-height: 31px;
    }
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1279px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const BolshoilogoBox = styled.div`
  img {
    width: 100px;
    border-radius: 50%;
    height: 100px;
  }
`;
const Border = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 70px;
  @media only screen and (max-width: 1279px) {
    margin-bottom: 60px;
  }
  ::after,
  ::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  ::after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #12141d;
    border-width: 21px;
    margin-left: -21px;
  }
  ::before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: rgba(255, 255, 255, 0.1);
    border-width: 23px;
    margin-left: -23px;
  }
`;
const Boxwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;
class PublisherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publisherList: undefined
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
    this.loadData();
  }

  loadData = async () => {
    let { eventList } = this.props.home;
    if (!eventList) {
      await this.props.getEventList();
      eventList = this.props.home.eventList;
    }

    const publisherId = this.props.match.params.id || undefined;
    if (publisherId) {
      const publisherList = eventList.filter((x) => removeWhiteSpaceRegex(x.publisher) === publisherId);
      this.setState({ publisherList });
    }
  };

  onItemClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/event-detail/${id}`);
  };

  onToggleWatchList = async (item) => {
    if (!item) return;

    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.props.history.push(`signup`);
      return;
    }
    item.isWatchlist = !item.isWatchlist;

    const result = await this.props.onToggleWatchListEvent(item.id);
    if (result) {
      this.loadData();
    }
  };

  render() {
    const loading_home = this.props.home.loading;
    const loading_watchList = this.props.watchList.loading;
    const loading = loading_home || loading_watchList;

    const { eventTypes } = this.props.misc;
    const { publisherList } = this.state;
    if (loading && !publisherList) {
      return <Loading />;
    }
    return (
      <>
        <Header history={this.props.history} onEventAdded={this.loadData} />

        {publisherList && publisherList.length > 0 && (
          <Container>
            <BolshoiBoxWrapper>
              <BolshoiBox>
                <BolshoilogoBox>
                  <img src={publisherList[0].publisherLogo || require('../../assets/images/no-image.svg')} alt='publisher Logo' />
                </BolshoilogoBox>
                <span>{publisherList[0].publisher} Presents</span>
                <p>
                  The legendary Bolshoi Theatre in Moscow has announced it will broadcast its best classic performances
                  online for free in response to new restrictions to slow the spread of coronavirus.
                </p>
              </BolshoiBox>
            </BolshoiBoxWrapper>
            <Border></Border>
            <Boxwrapper>
              {publisherList.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    item={item}
                    eventTypes={eventTypes}
                    isWatchListItem={item.isWatchlist}
                    onItemClicked={() => {
                      this.onItemClicked(item.id);
                    }}
                    onWatchListIconClicked={() => {
                      this.onToggleWatchList(item);
                    }}
                  />
                );
              })}
            </Boxwrapper>
          </Container>
        )}
        <Footer history={this.props.history} />
      </>
    );
  }
}

PublisherDetail.propTypes = {
  message: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  watchList: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  home: state.home,
  auth: state.auth,
  misc: state.misc,
  watchList: state.watchList
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getEventList,
  setErrorMessage,
  uploadImage,
  fetchIPLocation,
  onToggleWatchListEvent
})(PublisherDetail);
