import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setWatchList, setWatchListLoader } from '../actions/watchListActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';

/**
 * @desc get user watch list
 */
export const getUserWatchList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setWatchListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/watchList`);
    const { data } = response.data;
    dispatch(setWatchList(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch watchList events', dispatch);
    return false;
  } finally {
    dispatch(setWatchListLoader(false));
  }
};

/**
 * @desc Toggle Watch list  
 * @param {*} obj event obj
 */
export const onToggleWatchListEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!eventId) {
      dispatchError('Event Id not found', dispatch);
      return;
    }
    dispatch(setWatchListLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/watchList/${eventId}`, {});
    await dispatch(getUserWatchList());
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to toggle event', dispatch);
    return false;
  } finally {
    dispatch(setWatchListLoader(true));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
