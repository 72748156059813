import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import classNames from 'classnames';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (event) => {
    let { value } = event.target;
    const { dataItemKey = 'id' } = this.props;
    if (value) if (this.props.onChange) this.props.onChange(value[dataItemKey]);
  };

  render() {
    const { property, dataArray = [], value, defaultItem, textField = 'name', dataItemKey = 'id' } = this.props;

    let selectedVal;
    selectedVal = dataArray.find((x) => x[dataItemKey] === value) || null;
    return (
      <div className='timebox'>
        <DropDownList
          className={classNames({ 'default': !selectedVal })}
          defaultItem={defaultItem ? { [textField]: defaultItem } : undefined}
          name={property}
          id={property}
          data={dataArray}
          value={selectedVal}
          textField={textField}
          dataItemKey={dataItemKey}
          onChange={(e) => {
            this.onChange(e);
          }}
        />
      </div>
    );
  }
}

export default Dropdown;
