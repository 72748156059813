import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { isEmail, getAPIResponseError, isEmpty } from '../global/Helpers';
import { setAuthLoader, setCurrentUser, setUserProfile } from '../actions/authActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { setAuthToken, clearAuthToken } from '../utils/authTokenHelpers';
import localdataService from './localdataService';
import store from './../store/store';
import { firebaseConfig } from '../global/constant';

// Initialize Firebase
if (window.firebase) window.firebase.initializeApp(firebaseConfig);

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (!obj.username) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (isEmail(obj.username) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/login`, obj);
    const { data } = response.data;
    dispatch(setAuthLoader(false));
    dispatch(setLoginToken(data));
    return true;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(
      getAPIResponseError(e, dispatch) || "Can't sign in, please verify your login information",
      dispatch
    );
    return false;
  }
};

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const getNewToken = (refresh_token) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/token`, { refresh_token });
    dispatch(setLoginToken(response.data.data));
    return response.data.data;
  } catch (e) {
    dispatchAuthError(
      getAPIResponseError(e, dispatch) || "Can't sign in, please verify your login information",
      dispatch
    );
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Signup - signup user and set login information after successfuly signup
 * @param {*} obj Data Obj
 */
export const signup = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Name is Required', dispatch);
      return;
    } else if (!obj.name || isEmpty(obj.name)) {
      dispatchAuthError('Name is Required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!obj.confirmPassword) {
      dispatchAuthError('Confirm password is Required', dispatch);
      return;
    } else if (obj.password !== obj.confirmPassword) {
      dispatchAuthError('Password not matched', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/signup`, obj);
    const { data } = response.data;
    dispatch(setAuthLoader(false));
    dispatch(setLoginToken(data));
    return true;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(getAPIResponseError(e, dispatch) || 'Unable to signup, please try again', dispatch);
    return false;
  }
};

/**
 * @desc set login token and set user
 * @param {*} data Data obj
 * @param {*} isSaveInLocal flag for saving data in local storage
 */
export const setLoginToken = (data, isSaveInLocal = true) => (dispatch) => {
  // save auth deteils and set token in header for request
  setAuthToken(data.access_token);
  //save user in localstorage
  if (isSaveInLocal) localdataService.setCurrentUser(data);
  // Decode token to get user data
  let user = {
    ...data,
    access_token: undefined
  };
  delete user['access_token'];
  // Set current user in redux
  dispatch(setCurrentUser(user));

  // Set Analytics
  // setUser(user);
  // setLogUser(user);
};

/**
 * @desc Log user out
 */
export const logout = () => (dispatch) => {
  /**
   * Remove token from localStorage
   * Remove auth header for future requests
   * Set current user to {} which will set isAuthenticated to false
   */
  clearAuthToken();
  localdataService.ClearAllKeys();
  dispatch(setCurrentUser({}));
  // for Analytics
  // trackActivity('signed out');
  // clearUser();
};

/**
 * @desc  Forgot Password email
 * @param {*} obj user obj
 */
export const forgotPassword = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Registered email address is required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Registered email address is required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/forgot-password`, obj);
    const { data } = response;
    dispatch(setAuthLoader(false));
    dispatch(setSuccessMessage(data.message));
    return true;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(getAPIResponseError(e, dispatch) || 'Something went wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  Reset Password email
 * @param {*} obj user obj
 */
export const resetPassword = (obj) => async (dispatch) => {
  if (!window.firebase) return;
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('New Password is required', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('New Password is required', dispatch);
      return;
    } else if (!obj.confirmPassword) {
      dispatchAuthError('Confirm Password is required', dispatch);
      return;
    } else if (obj.password !== obj.confirmPassword) {
      dispatchAuthError('New Password and Confirm Password must be same', dispatch);
      return;
    } else if (!obj.token) {
      dispatchAuthError('Token is required', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const resultPromise = new Promise(function (resolve, reject) {
      window.firebase
        .auth()
        .confirmPasswordReset(obj.token, obj.password)
        .then(function (result) {
          dispatch(setSuccessMessage('Password updated successfully, Please retry login with the updated password.'));
          resolve(true);
        })
        .catch(function (error) {
          var errorMessage = error.message;
          console.log('ERROR: ', error);
          reject(errorMessage);
        });
    });

    const result = await resultPromise;
    return result;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something went wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc verify Code
 * @param {string} code
 */
export const verifyCode = (code) => async (dispatch) => {
  try {
    if (!code) {
      dispatchAuthError('Code is Required', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/auth/verify`, { code: code });

    const { data } = response;
    console.log('data', data);
    //TODO : remove requiredVerification flag from user details
    return true;
  } catch (e) {
    dispatchAuthError(e || 'Something went wrong, please try again later');
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc resend verification code
 */
export const resendVerificationCode = () => async (dispatch) => {
  try {
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/auth/verification-code`, {});

    const { data } = response;
    //TODO : remove requiredVerification flag from user details
    if (data) {
      dispatch(setSuccessMessage(data.message));
    }
    return true;
  } catch (e) {
    dispatchAuthError(e || 'Something went wrong, please try again later');
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  get User Profile
 */
export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setAuthLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/user/profile`);
    const { data } = response.data;
    if (data) {
      dispatch(setUserProfile(data));
    } else {
      dispatch(setUserProfile({}));
    }
    return data;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something went wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  update User Profile
 * @param {*} obj user obj
 */
export const updateUserProfile = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Fields to be updated is required', dispatch);
      return;
    } else if (obj.displayName && isEmpty(obj.displayName)) {
      dispatchAuthError(`Name can't be empty`, dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/user/profile`, obj);
    const { data, message } = response.data;
    if (data) {
      dispatch(setUserProfile(data));
      dispatch(setSuccessMessage(message));
    } else {
      dispatch(setUserProfile({}));
    }
    return data;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something went wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  update User Profile
 * @param {*} obj user obj
 */
export const updateUserProfilePic = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Fields to be updated is required', dispatch);
      return;
    } else if (obj.profile_url && isEmpty(obj.profile_url)) {
      dispatchAuthError(`Url can't be empty`, dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/user/profile`, obj);
    const { data } = response.data;
    if (data) {
      dispatch(setUserProfile(data));
    } else {
      dispatch(setUserProfile({}));
    }
    return data;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something went wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

function dispatchAuthError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

//Get a new token and pass that token to request to prevent unauthorized request error
export const handleAxiosRequest = () => {
  axios.interceptors.request.use(
    async (config) => {
      try {
        const auth = store.getState().auth;
        if (auth && auth.user) {
          const token = auth.user.idToken;
          //If have token and not a new token api, validate token before request and request a new token if expired
          if (token && !config.url.endsWith('/auth/token')) {
            const decoded = jwt_decode(token);
            const currentTime = Date.now() / 1000 - 10; //Minus 10 seconds before expiring
            if (decoded.exp < currentTime) {
              console.log('get new token');
              const refresh_token = auth.user.refresh_token;
              //Request a new token using refresh token
              const result = await store.dispatch(getNewToken(refresh_token));
              if (result) {
                const newToken = result.access_token;
                if (newToken) config.headers.Authorization = `Bearer ${newToken}`;
              }
            }
          }
        }
      } catch (e) {
        console.log('ERROR at axios.interceptors.request', e);
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};
