import * as actions from '../../actions/types';
import { EVENT_START_TIME, EVENT_END_TIME } from '../../global/constant';

const initialState = {
  eventList: undefined,
  eventDetail: undefined,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_HOME_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_EVENT_LIST:
      const filteredEventList = filterListByField(action.payload, EVENT_END_TIME)
      const sortedEventList = sortListByField(filteredEventList, EVENT_START_TIME)
      return {
        ...state,
        eventList: sortedEventList
      };
    case actions.SET_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: action.payload
      };
    default:
      return state;
  }
}

const filterListByField = (list, fieldName) => {
  if (!list || !fieldName) return [];
  const currentTime = new Date().getTime();
  return list.filter((x) => new Date(x[fieldName]).getTime() >= currentTime);
}

const sortListByField = (list, fieldName) => {
  if (!list || !fieldName) return [];

  return list.sort((a, b) => {
    return new Date(a[fieldName]).getTime() - new Date(b[fieldName]).getTime();
  });
};
