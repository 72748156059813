import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setChannelList, setEventTypeList, setMiscLoader, setSubjectList } from '../actions/miscActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';

/**
 * @desc get subject list
 */
export const getSubjects = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setMiscLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/subject`);
    const { data } = response.data;
    dispatch(setSubjectList(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch subjects list', dispatch);
    return false;
  } finally {
    dispatch(setMiscLoader(false));
  }
};

/**
 * @desc get channel list
 */
export const getChannels = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setMiscLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/channel`);
    const { data } = response.data;
    dispatch(setChannelList(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch channels list', dispatch);
    return false;
  } finally {
    dispatch(setMiscLoader(false));
  }
};

/**
 * @desc get eventType list
 */
export const getEventTypes = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setMiscLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/eventType`);
    const { data } = response.data;
    dispatch(setEventTypeList(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch eventTypes list', dispatch);
    return false;
  } finally {
    dispatch(setMiscLoader(false));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
