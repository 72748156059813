import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import globalStyle from '../../assets/universal/globalStyles';
import play from '../../assets/images/14.png';
import styled from 'styled-components';
import { getEventItem, getEventList, checkEventLiveStatus } from '../../services/eventService';
import Loading from '../../components/Loading/Loading';
import { getFormattedTime, isEmpty } from '../../global/Helpers';
import Countdown, { zeroPad } from 'react-countdown';
import ListItem from '../Home/ListItem';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { setEventDetail } from '../../actions/homeActions';
import Popup from 'reactjs-popup';
import { onToggleWatchListEvent } from '../../services/watchListService';
import Share from '../../assets/images/share.svg';
import CopyLinkIcon from '../../assets/images/ic-copy.svg';
import Facebookicon from '../../assets/images/ic-fb.svg';
import Twittericon from '../../assets/images/ic-twitter.svg';
import Mailicon from '../../assets/images/mail.svg';
import { PROJECT_ID } from '../../global/constant';

const Container = globalStyle.Container;
const ContainerSm = styled.div`
  max-width: 940px;
  margin: auto;
  display: flex;
  justify-content: center;
  a {
    width: 100%;
    height: 0;
    padding-bottom: 54.25%;
    position: relative;
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    a {
      padding-bottom: 64.25%;
    }
  }
`;
const HeroEvent = styled.div`
  text-align: center;
  padding-top: 54px;
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @media only screen and (max-width: 1279px) {
    padding-bottom: 50px;
  }
`;
const HeroLabel = styled.div``;
const HeroBtn = styled.p`
  padding: 2px 12px;
  display: inline-block;
  background: #2a2b34;
  border-radius: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin: auto auto 20px auto;
`;
const HeadingTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 35px;
  @media only screen and (max-width: 767px) {
    font-size: 29px;
    line-height: 31px;
    margin-bottom: 20px;
  }
`;
const Time = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 940px;
  width: 100%;
  margin: auto;
  div {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 20px;
    @media only screen and (max-width: 767px) {
      display: block;
      margin-right: 0px;
    }
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
const Ellipse = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  margin-right: 20px
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const HeroImage = styled.div`
  margin: 50px 0;
  position: relative;
  @media only screen and (max-width: 767px) {
    margin: 30px 0;
  }
`;
const Play = styled.img`
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 9;
  transform: translate(50%, -50%);
  @media only screen and (max-width: 1279px) {
    width: 80px;
  }
  @media only screen and (max-width: 767px) {
    width: 50px;
    top: 50%;
    right: 50%;
  }
`;
const LabelBox = styled.div`
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 10px 20px 10px 20px;
  background-color: #d71515;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    padding: 5px;
    background-color: #d71515;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }
  ::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 42px solid #d71515;
    border-left: 30px solid transparent;
    position: absolute;
    right: 100%;
    top: 0;
    @media only screen and (max-width: 767px) {
      border-top: 28px solid #d71515;
      border-left: 22px solid transparent;
    }
  }
`;
const Bg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: -999px;
  right: -999px;
  top: -999px;
  bottom: -999px;
  margin: auto;
  @media only screen and (max-width: 1279px) {
    width: auto;
  }
`;
const HeroText = styled.div`
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: initial;
    color: rgba(255, 255, 255, 0.5);
    max-width: 945px;
    margin: auto;
    text-align: left;
  }
  padding-bottom: 50px;
`;
const SocialDetailsIcon = styled.div`
  max-width: 945px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const SocialIcon = styled.div``;
const SocialDetailsHeading = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 17px;
`;
const Boxwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;
const CalendarBtn = styled.button`
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 36px;
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-left: auto;
    margin-top: 10px;
  }
`;
const CalendarA = styled.a`
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  border: 0;
  margin-right: 20px;
  padding: 7px;
`;
const LinkBtn = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
`;
const SocialButton = styled.a`
  margin-right: 20px;
`;
const Timelock = styled.span`
  margin: 0 8px ;
  background-color: #2a2b34;
  border-radius: 2px;
  padding: 7px 7px;
`;
const Timelockdot = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: inline-block;
`;
const ShareIcon = styled.img`
  margin-right: 8px;
`;
const ContainerSmall = styled.div`
  align-items: center;
  margin: 0 auto;
  display: flex;
`;
const DropUl = styled.div`
  background: #1e2028;
  border-radius: 5px;
  width: 175px;
  padding: 25px 25px 5px 25px;
  position: absolute;
  top: 20px;
  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    border: 10px solid #1e2028;
    transform: rotate(-45deg);
    left: 20px;
  }
  @media only screen and (max-width: 1279px) {
    left: -58px;
    ::after {
      content: '';
      right: 20px;
      left: auto;
    }
  }
`;
const DropLi = styled.div`
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  padding: 6px;
  img {
    margin-right: 12px;
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
  }
  :hover {
    background: #2a2b34;
    border-radius: 5px;
  }
`;

class EventDetail extends Component {
  prevEventId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isEventLive: false,
      publisherList: []
    };
  }

  componentDidMount() {
    const eventId = this.props.match.params.id || undefined;
    this.prevEventId = eventId;
    this.loadData(eventId);
  }

  componentWillUnmount() {
    this.props.setEventDetail();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.prevEventId !== newProps.match.params.id) {
      this.prevEventId = newProps.match.params.id;
      this.loadData(newProps.match.params.id);
    }
  }

  loadData = async (eventId) => {
    let { eventList } = this.props.home;
    if (!eventList) {
      await this.props.getEventList();
      eventList = this.props.home.eventList;
    }

    if (eventId) {
      const eventDetail = await this.props.getEventItem(eventId);
      if (eventDetail) {
        const publisherId = eventDetail.publisher || undefined;
        let publisherList = [];
        if (publisherId) {
          publisherList = eventList.filter((x) => x.publisher === publisherId && x.id !== eventDetail.id);
        }
        const isEventLive = checkEventLiveStatus(eventDetail);
        this.setState({ isEventLive, publisherList });
      }
    }
  };

  onItemClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/event-detail/${id}`);
  };

  onPublisherNameClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/publisher-detail/${id}`);
  };

  onToggleWatchList = async (item) => {
    if (!item) return;

    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.props.history.push(`signup`);
      return;
    }
    item.isWatchlist = !item.isWatchlist;

    const result = await this.props.onToggleWatchListEvent(item.id);
    if (result) {
      this.loadData();
    }
  };

  // Renderer callback with condition
  renderCountDown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return null;
    } else {
      // Render a countdown
      return (
        <>
          <Ellipse></Ellipse>
          <Timelock className=' timelockml'>{days}D</Timelock>
          <Timelockdot>:</Timelockdot>
          <Timelock> {hours}H</Timelock>
          <Timelockdot>:</Timelockdot>
          <Timelock className='timelockmr'> {minutes}M</Timelock>
        </>
      );
    }
  };

  onCompleteCountDown = () => {
    this.setState({ isEventLive: true });
  };

  onAddToCalendarClick = () => {
    const { eventDetail } = this.props.home;
    if (eventDetail) {
      var icsMSG =
        'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Our Company//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:me@google.com\nDTSTAMP:20120315T170000Z\nATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com\nORGANIZER;CN=Me:MAILTO::me@gmail.com\nDTSTART:' +
        eventDetail.startTime +
        '\nDTEND:' +
        eventDetail.endTime +
        '\nLOCATION:' +
        '' +
        '\nSUMMARY:' +
        eventDetail.eventName +
        '\nEND:VEVENT\nEND:VCALENDAR';
      window.open('data:text/calendar;charset=utf8,' + escape(icsMSG));
    }
  };

  copyLinkToClipboard = () => {
    const el = this.copyLink;
    el.style.display = 'block';
    el.select();
    document.execCommand('copy');
    el.style.display = 'none';
  };

  render() {
    const loading_home = this.props.home.loading;
    const loading_watchList = this.props.watchList.loading;
    const loading = loading_home || loading_watchList;

    const shareUrl = window.location && `https://${PROJECT_ID}.web.app${window.location.pathname}`;
    const { eventDetail = {} } = this.props.home;
    const { eventTypes } = this.props.misc;
    const { isEventLive, publisherList } = this.state;
    if (loading && !eventDetail) {
      return <Loading />;
    }
    const { user } = this.props.auth;
    var icsMSG =
      'data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0d%0aVERSION:2.0%0d%0aPRODID:-//Our Company//NONSGML v1.0//EN%0d%0aBEGIN:VEVENT%0d%0aUID:' +
      user.email +
      '%0d%0aDTSTAMP:20120315T170000Z%0d%0aATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:' +
      user.email +
      '%0d%0aORGANIZER;CN=' +
      eventDetail.name +
      ':MAILTO::' +
      eventDetail.publisher +
      '%0d%0aDTSTART:' +
      eventDetail.startTime +
      '%0d%0aDTEND:' +
      eventDetail.endTime +
      '%0d%0aLOCATION:%0d%0aSUMMARY:' +
      eventDetail.eventName +
      '%0d%0aEND:VEVENT%0d%0aEND:VCALENDAR';
    return (
      <>
        <Header history={this.props.history} />
        <Container>
          {eventDetail && (
            <HeroEvent>
              <HeroLabel>
                <Container>
                  <HeroBtn>{eventDetail.eventType}</HeroBtn>
                  <HeadingTitle>{eventDetail.eventName}</HeadingTitle>
                  <Time>
                    <ContainerSmall>
                      <div>{eventDetail.subject}</div>
                      <Ellipse></Ellipse>
                      <div>{moment(eventDetail.startTime).format('MMM DD, YYYY')}</div>
                      <div>{`${getFormattedTime(eventDetail.startTime)} - ${getFormattedTime(
                        eventDetail.endTime
                      )}`}</div>
                      <Countdown
                        date={new Date(eventDetail.startTime)}
                        renderer={this.renderCountDown}
                        onComplete={this.onCompleteCountDown}
                      />
                      {!isEventLive && (
                        <>
                          <Ellipse></Ellipse>
                          <CalendarA
                            href={icsMSG}
                            download='download.ics'
                            target='_blank'
                            className='button button--secondary'
                            role='button'>
                            {' '}
                            Add to Calendar{' '}
                          </CalendarA>
                        </>
                      )}
                    </ContainerSmall>
                    <Popup
                      trigger={
                        <CalendarBtn type='button'>
                          <ShareIcon src={Share} alt='share' />
                          SHARE
                        </CalendarBtn>
                      }
                      position='bottom left'
                      closeOnDocumentClick
                      mouseLeaveDelay={300}
                      mouseEnterDelay={0}
                      arrow={false}>
                      {(close) => (
                        <>
                          <DropUl>
                            <DropLi>
                              <input
                                type='text'
                                style={{ display: 'none' }}
                                className='form-control'
                                readOnly
                                value={shareUrl}
                                ref={(copyLink) => (this.copyLink = copyLink)}
                              />
                              <LinkBtn className='socialicon' onClick={this.copyLinkToClipboard}>
                                <img src={CopyLinkIcon} alt='Copy Link' />
                                <span>Copy Link</span>
                              </LinkBtn>
                            </DropLi>
                            <DropLi>
                              <FacebookShareButton className='socialicon' url={shareUrl}>
                                <img src={Facebookicon} alt='Facebook' />
                                <span>Facebook</span>
                              </FacebookShareButton>
                            </DropLi>
                            <DropLi>
                              <TwitterShareButton className='socialicon' url={shareUrl}>
                                <img src={Twittericon} alt='Twitter' />
                                <span>Twitter</span>
                              </TwitterShareButton>
                            </DropLi>
                            <DropLi>
                              <EmailShareButton className='socialicon' url={shareUrl}>
                                <img src={Mailicon} alt='Mail' />
                                <span>Mail</span>
                              </EmailShareButton>
                            </DropLi>
                          </DropUl>
                        </>
                      )}
                    </Popup>
                  </Time>
                </Container>
              </HeroLabel>
              <HeroImage>
                <ContainerSm>
                  <a href={eventDetail.eventUrl} target='_blank' rel='noopener noreferrer'>
                    <Play src={play} alt='Play Icon' />
                    {isEventLive && <LabelBox>LIVE</LabelBox>}
                    <Bg src={eventDetail.publisherThumbnail} alt='' />
                  </a>
                </ContainerSm>
              </HeroImage>
              <HeroText className='herotext'>
                <p dangerouslySetInnerHTML={{ __html: eventDetail.eventDesc }}></p>
              </HeroText>
              {(!isEmpty(eventDetail.facebookUrl) ||
                !isEmpty(eventDetail.twitterUrl) ||
                !isEmpty(eventDetail.twitchUrl) ||
                !isEmpty(eventDetail.linkedInUrl)) && (
                  <SocialDetailsIcon>
                    <SocialDetailsHeading>Social Details</SocialDetailsHeading>
                    <SocialIcon>
                      {!isEmpty(eventDetail.facebookUrl) && (
                        <SocialButton href={eventDetail.facebookUrl} target='_blank'>
                          <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g>
                              <path
                                d='M25 50.0002C38.8071 50.0002 50 38.8074 50 25.0002C50 11.1931 38.8071 0.000244141 25 0.000244141C11.1929 0.000244141 0 11.1931 0 25.0002C0 38.8074 11.1929 50.0002 25 50.0002Z'
                                fill='#1E2028'
                              />
                              <path
                                d='M29.8667 25.0385H26.431V37.6252H21.2257V25.0385H18.75V20.6151H21.2257V17.7526C21.2257 15.7056 22.198 12.5002 26.4773 12.5002L30.3331 12.5164V16.8101H27.5355C27.0766 16.8101 26.4313 17.0394 26.4313 18.0158V20.6192H30.3214L29.8667 25.0385Z'
                                fill='white'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0'>
                                <rect width='50' height='50' fill='white' transform='translate(0 0.000244141)' />
                              </clipPath>
                            </defs>
                          </svg>
                        </SocialButton>
                      )}

                      {!isEmpty(eventDetail.twitterUrl) && (
                        <SocialButton href={eventDetail.twitterUrl} target='_blank'>
                          <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g>
                              <path
                                d='M24.9998 49.9998C38.8068 49.9998 49.9996 38.807 49.9996 25C49.9996 11.193 38.8068 0.000244141 24.9998 0.000244141C11.1928 0.000244141 0 11.193 0 25C0 38.807 11.1928 49.9998 24.9998 49.9998Z'
                                fill='#1E2028'
                              />
                              <path
                                d='M37.875 17.4415C36.9417 17.8554 35.9376 18.1353 34.8847 18.2603C35.9598 17.6162 36.7848 16.5971 37.1742 15.3807C36.1683 15.9775 35.0536 16.4104 33.8683 16.6437C32.9186 15.6323 31.5656 15.0002 30.0674 15.0002C27.1928 15.0002 24.8614 17.3317 24.8614 20.2062C24.8614 20.6143 24.9076 21.0115 24.9969 21.3927C20.6703 21.1757 16.834 19.1032 14.2661 15.9526C13.8181 16.7213 13.5611 17.6162 13.5611 18.5702C13.5611 20.3759 14.4808 21.97 15.877 22.9034C15.024 22.8766 14.2207 22.6425 13.5191 22.2515C13.5188 22.2737 13.5188 22.2958 13.5188 22.3175C13.5188 24.84 15.314 26.9439 17.6951 27.4218C17.2587 27.5414 16.7979 27.6047 16.3238 27.6047C15.9876 27.6047 15.6619 27.5724 15.3443 27.5119C16.0066 29.5797 17.9288 31.0849 20.2075 31.1272C18.4254 32.5237 16.181 33.3558 13.7412 33.3558C13.3219 33.3558 12.9065 33.3313 12.5 33.2828C14.8027 34.7604 17.5398 35.6219 20.4796 35.6219C30.0553 35.6219 35.292 27.6893 35.292 20.8092C35.292 20.5836 35.2869 20.3588 35.2769 20.136C36.2948 19.4022 37.1773 18.4855 37.875 17.4415Z'
                                fill='#F1F2F2'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0'>
                                <rect width='50' height='50' fill='white' transform='translate(0 0.000244141)' />
                              </clipPath>
                            </defs>
                          </svg>
                        </SocialButton>
                      )}

                      {!isEmpty(eventDetail.twitchUrl) && (
                        <SocialButton href={eventDetail.twitchUrl} target='_blank'>
                          <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g>
                              <path
                                d='M24.9998 49.9996C38.8068 49.9996 49.9996 38.8068 49.9996 24.9998C49.9996 11.1928 38.8068 0 24.9998 0C11.1928 0 0 11.1928 0 24.9998C0 38.8068 11.1928 49.9996 24.9998 49.9996Z'
                                fill='#1E2028'
                              />
                              <g>
                                <path
                                  d='M13.5156 16.849V34.2385H19.5042V37.5H22.774L26.0385 34.2375H30.9396L37.475 27.7187V12.5H15.1479L13.5156 16.849ZM17.326 14.6719H35.2969V26.6292L31.4844 30.4333H25.4937L22.2292 33.6906V30.4333H17.326V14.6719Z'
                                  fill='white'
                                />
                                <path d='M23.3174 19.0229H25.4945V25.5438H23.3174V19.0229Z' fill='white' />
                                <path d='M29.3047 19.0229H31.4828V25.5438H29.3047V19.0229Z' fill='white' />
                              </g>
                            </g>
                            <defs>
                              <clipPath id='clip0'>
                                <rect width='50' height='50' fill='white' transform='translate(0 0.000244141)' />
                              </clipPath>
                              <clipPath id='clip1'>
                                <rect width='25' height='25' fill='white' transform='translate(12.5 12.5)' />
                              </clipPath>
                            </defs>
                          </svg>
                        </SocialButton>
                      )}

                      {!isEmpty(eventDetail.linkedInUrl) && (
                        <SocialButton href={eventDetail.linkedInUrl} target='_blank'>
                          <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M24.9999 0C38.8078 0 50 11.1937 50 25.0001C50 38.8064 38.8078 50 24.9999 50C11.192 50 0 38.8063 0 25.0001C0 11.1939 11.1922 0 24.9999 0Z'
                              fill='#1E2028'
                            />
                            <path
                              d='M14.0876 34.3499H18.7689V18.7468H14.0876V34.3499ZM32.3251 18.2063C30.0532 18.2063 28.0204 19.036 26.5783 20.8673V18.6954H21.8799V34.3501H26.5783V25.8845C26.5783 24.0954 28.2174 22.35 30.2705 22.35C32.3236 22.35 32.8299 24.0954 32.8299 25.8407V34.3486H37.5112V25.4923C37.511 19.3406 34.5986 18.2063 32.3251 18.2063ZM16.4063 17.1875C17.7001 17.1875 18.7501 16.1374 18.7501 14.8437C18.7501 13.5499 17.7001 12.5 16.4063 12.5C15.1126 12.5 14.0625 13.5501 14.0625 14.8438C14.0625 16.1376 15.1126 17.1875 16.4063 17.1875Z'
                              fill='white'
                            />
                          </svg>
                        </SocialButton>
                      )}
                    </SocialIcon>
                  </SocialDetailsIcon>
                )}
            </HeroEvent>
          )}
          <Boxwrapper>
            {publisherList &&
              publisherList.length > 0 &&
              publisherList.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    item={item}
                    eventTypes={eventTypes}
                    isWatchListItem={item.isWatchlist}
                    onItemClicked={() => {
                      this.onItemClicked(item.id);
                    }}
                    onWatchListIconClicked={() => {
                      this.onToggleWatchList(item);
                    }}
                    onPublisherNameClicked={this.onPublisherNameClicked}
                  />
                );
              })}
          </Boxwrapper>
        </Container>
        <Footer history={this.props.history} />
      </>
    );
  }
}

EventDetail.propTypes = {
  message: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  watchList: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  home: state.home,
  auth: state.auth,
  misc: state.misc,
  watchList: state.watchList
});

export default connect(mapStateToProps, {
  getEventItem,
  getEventList,
  setErrorMessage,
  clearResponseMessage,
  setEventDetail,
  onToggleWatchListEvent
})(EventDetail);
