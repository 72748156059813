import color from '../scss/_colors.scss';

export default {
  colors: {
   
    // --- Theme Context   
    Primary_clr: color.Primary_clr,
    Secondary_clr: color.Secondary_clr,
    White_clr: color.White_clr,
    Gray_Primary_clr: color.Gray_Primary_clr,
    Gray_Secondary_clr: color.Gray_Secondary_clr,

    // --- Button => Primary, Secondary
    Primary_btn_bg_clr: color.Primary_btn_bg_clr,
    Primary_btn_border_clr: color.Primary_btn_border_clr,
    Primary_btn_txt_clr: color.Primary_btn_txt_clr,
    Primary_btn_icon_clr: color.Primary_btn_icon_clr,
    Primary_btn_shadow_clr: color.Primary_btn_shadow_clr,
    Secondary_btn_bg_clr: color.Secondary_btn_bg_clr,
    Secondary_btn_border_clr: color.Secondary_btn_border_clr,
    Secondary_btn_txt_clr: color.Secondary_btn_txt_clr,
    Secondary_btn_icon_clr: color.Secondary_btn_icon_clr,
    Secondary_btn_shadow_clr: color.Secondary_btn_shadow_clr,

    // --- Background => Primary, Secondary
    Primary_background_clr: color.Primary_background_clr,
    Secondary_background_clr: color.Secondary_background_clr,
    SimualGray_bg_clr: color.SimualGray_bg_clr,
    Lightgray_bg_clr: color.Lightgray_bg_clr,
    GrayShadow_bg_clr: color.GrayShadow_bg_clr,

    // --- Text => Primary, Secondary
    Primary_text_clr: color.Primary_text_clr,
    Secondary_text_clr: color.Secondary_text_clr,
    Theme_text_clr: color.Theme_text_clr,

    // --- Border
    Primary_border_clr: color.Primary_border_clr,

  }
};

