import axios from 'axios';
import { setContentLoader, setPrivacyContent, setTermsContent } from '../actions/contentActions';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { TYPE_PRIVACY, TYPE_TERMS } from '../global/constant';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';

/**
 * @desc get content
 * @param type // terms or privacy
 */
export const getContent = (type) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setContentLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/content/${type}`);
    const { data } = response.data;
    if (type === TYPE_TERMS) {
      dispatch(setTermsContent(data));
    } else if (type === TYPE_PRIVACY) {
      dispatch(setPrivacyContent(data));
    }
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch content', dispatch);
    return false;
  } finally {
    dispatch(setContentLoader(false));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
