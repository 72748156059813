import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage, setErrorMessage } from '../../../actions/messageActions';
import styled, { css } from 'styled-components';
import Status from '../../../components/StatusBox/Status';
import { resendVerificationCode, verifyCode } from '../../../services/authService';
import localdataService from '../../../services/localdataService';
import AuthLayout from '../AuthLayout';
import classNames from 'classnames';

const AuthBoxDetailWrapper = styled.div`
  width: 100%;
  p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      color: #fe715b;
      cursor: pointer;
    }
  }
`;
const AuthBoxDetail = styled.div`
  text-align: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
  .h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: -1.08px;
    color: #ffffff;
    margin-bottom: 5px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthForm = styled.form`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const FormColFull = styled.div`
  width: 100%;
`;
const Attributes = styled.a`
display: block;
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #ff715b;
margin: 26px 0 50px 0;
cursor: pointer;
text-align: right;
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 0;
  padding: 14px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  ::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.3);
  }
`;
const ContentHead = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  margin-top: 20px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const ButtonBox = styled.div`
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
`;
const Button = styled.button`
  background: #ff715b;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
  border: 0;
  border-radius: 4px;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;

export class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      loading: false
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleCodeChange = (e) => {
    const code = e.target.value;
    this.setState({ code });
  };

  onChangeEmail = () => {
    this.props.history.push('/signup');
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      const { code } = this.state;
      if (!code || code.length !== 6) {
        this.props.setErrorMessage({ field: 'code', msg: 'Code is required' });
        return;
      }
      const result = await this.props.verifyCode(code);
      if (result == true) {
        //update user details with verified
        const userDetails = localdataService.getCurrentUser();
        delete userDetails.verificationRequired;
        localdataService.setCurrentUser(userDetails);
        this.props.history.push('/home');
      }
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onClickResend = async () => {
    try {
      this.setState({ loading: true });
      await this.props.resendVerificationCode();
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const authLoading = this.props.auth.loading;
    const stateLoading = this.state.loading;
    const loading = stateLoading || authLoading;
    const { code } = this.state;
    const { user = {} } = this.props.auth;
    return (
      <>
        <AuthLayout>
          <AuthBoxDetailWrapper>
            <AuthBoxDetail>
              <h2 className='h2'>Verify your email</h2>
              <span>{`please enter the 6 digit code sent to your email address ${user.email}`}</span>
            </AuthBoxDetail>
            <AuthForm onSubmit={this.onSubmit}>
              <FormColFull>
                <ContentHead first>Verification Code</ContentHead>
                <Input
                  type='text'
                  placeholder='enter your 6 digit code'
                  autocomplete='off'
                  maxLength={7}
                  name='code'
                  required=''
                  value={code}
                  keyboardType='phone-pad'
                  onChange={this.handleCodeChange}
                />
              </FormColFull>
              <Attributes disabled={loading} onClick={this.onClickResend}>
                Resend code
              </Attributes>
              {this.renderErrors()}
              {this.renderSuccess()}
              <ButtonBox  className={classNames("btn-save-loading", { 'loading': loading })}>
                <Button type='submit' disabled={loading} className="btn base_btn">
                  Confirm
                </Button>
              </ButtonBox>
            </AuthForm>
            <p>
              <a onClick={this.onChangeEmail} disabled={loading}>
                Change Email address
              </a>
            </p>
          </AuthBoxDetailWrapper>
        </AuthLayout>
      </>
    );
  }
}

VerificationCode.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  setErrorMessage,
  verifyCode,
  resendVerificationCode
})(VerificationCode);
