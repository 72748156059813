import { combineReducers } from 'redux';
import messageReducer from './messageReducer';
import homeReducer from './homeReducer';
import authReducer from './authReducer';
import watchListReducer from './watchListReducer';
import miscReducer from './miscReducer';
import contentReducer from './contentReducer';

export default combineReducers({
  auth: authReducer,
  message: messageReducer,
  home: homeReducer,
  watchList: watchListReducer,
  misc: miscReducer,
  content: contentReducer
});
