import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { clearResponseMessage } from '../../../actions/messageActions';
import { login } from '../../../services/authService';
import Status from '../../../components/StatusBox/Status';
import localdataService from '../../../services/localdataService';
import classNames from 'classnames';
import AuthLayout from '../AuthLayout';

const AuthBoxDetailWrapper = styled.div`
  width: 100%;
  display: block;
  p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      color: #fe715b;
      cursor: pointer;
    }
  }
`;
const AuthBoxDetail = styled.div`
  text-align: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
  .h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: -1.08px;
    color: #ffffff;
    margin-bottom: 5px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthForm = styled.form`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const FormColFull = styled.div`
  display: block;
  width: 100%;
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 0;
  padding: 14px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  ::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.3);
  }
`;
const ContentHead = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  margin-top: 20px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const CheckBox = styled.div`
  margin: 25px 0 50px 0;
  display: flex;
  width: 100%;
  @media only screen and (max-width: 1365px) {
    margin: 30px 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 30px 0 30px 0px;
    flex-wrap: wrap;
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 15px;
  }
`;
const Attributes = styled.a`
  margin-left: auto;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ff715b;
  cursor: pointer;
`;
const ButtonBox = styled.div`
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
`;
const ButtonWrap = styled.div``;
const Button = styled.button`
  background: #ff715b;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
  border: 0;
  border-radius: 4px;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;
const Label = styled.label`
  display: flex;
`;
const LabelText = styled.p`
  margin-left: 15px;
  @media only screen and (max-width: 1023px) {
    margin-left: 3px;
  }
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.05)')};
  outline: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 150ms;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRemeberMe: false
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleEmailInputChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  handlePasswordInputChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  };

  handleRememberMeCheckboxChange = (e) => {
    const isRemeberMe = e.target.checked;
    this.setState({ isRemeberMe });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email, password, isRemeberMe } = this.state;
    const loginObj = {
      username: email,
      password: password,
      remember: isRemeberMe
    };

    const result = await this.props.login(loginObj);
    if (result === true) {
      const userDetails = localdataService.getCurrentUser();
      if (userDetails && userDetails.verificationRequired) {
        this.props.history.push('/verification-code');
        return;
      }
      this.props.history.push('/home');
    }
  };

  onClickForgotPassword = () => {
    this.props.history.push('/forgot-password');
  };

  onSignupClick = () => {
    this.props.history.push('/signup');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { email, password, isRemeberMe } = this.state;
    return (
      <>
        <AuthLayout>
          <AuthBoxDetailWrapper>
            <AuthBoxDetail>
              <h2 className='h2'>Log In</h2>
              <span>Sign into your account!</span>
            </AuthBoxDetail>
            <AuthForm onSubmit={this.onSubmit}>
              <FormColFull>
                <ContentHead first>Email</ContentHead>
                <Input
                  type='email'
                  maxLength='256'
                  name='Email'
                  placeholder='enter your email address'
                  autocomplete='off'
                  required=''
                  value={email}
                  onChange={this.handleEmailInputChange}
                />
              </FormColFull>
              <FormColFull>
                <ContentHead>Password</ContentHead>
                <Input
                  type='password'
                  maxLength='256'
                  name='Password'
                  required=''
                  placeholder='enter password'
                  autocomplete='off'
                  value={password}
                  onChange={this.handlePasswordInputChange}
                />
              </FormColFull>
              <CheckBox>
                <div>
                  <Label>
                    <CheckboxContainer>
                      <HiddenCheckbox
                        checked={isRemeberMe}
                        value={isRemeberMe}
                        onChange={this.handleRememberMeCheckboxChange}
                      />
                      <StyledCheckbox checked={isRemeberMe}>
                        {isRemeberMe && (
                          <Icon className='icon' viewBox='0 0 24 24'>
                            <polyline points='20 6 9 17 4 12' />
                          </Icon>
                        )}
                      </StyledCheckbox>
                    </CheckboxContainer>
                    <LabelText>Remember Me</LabelText>
                  </Label>
                </div>
                <Attributes disabled={loading} onClick={this.onClickForgotPassword}>
                  Forgot password?
                </Attributes>
              </CheckBox>
              {this.renderErrors()}
              {this.renderSuccess()}
              <ButtonBox>
                <ButtonWrap className={classNames("btn-save-loading", { 'loading': loading })}>
                  <Button type='submit' disabled={loading} className="btn base_btn">
                    Log In
                  </Button>
                </ButtonWrap>
              </ButtonBox>
            </AuthForm>
            <p>
              Don’t have an account?{' '}
              <a disabled={loading}  onClick={this.onSignupClick}>
                Sign Up
              </a>
            </p>
          </AuthBoxDetailWrapper>
        </AuthLayout>
      </>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  login
})(Login);
