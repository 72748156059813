import styled from 'styled-components';
import theme from './themeColor';

const Container = styled.div`
  max-width: 1296px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;
const Row = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  @media only screen and (max-width: 1365px) {
    margin: 0;
  }
`;
const Flexbox = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;
const Contentbox = styled.div`
  position: relative;
  z-index: 1;
  padding: 45px 0 0 0;
  @media only screen and (max-width: 767px) {
    padding: 20px 0 0 0;
  }
`;

// --- Typography
const H1style = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 54px;
  letter-spacing: -1.08px;
  color: ${theme.colors.Secondary_text_clr};
  margin: 0;
  @media only screen and (max-width: 1279px) {
    font-size: 48px;
    line-height: 48px;
  }
  @media only screen and (max-width: 1023px) {
    font-size: 44px;
    line-height: 48px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 30px;
  }
`;
const H2style = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: ${theme.colors.Primary_text_clr};
  margin: 0;
  @media only screen and (max-width: 1279px) {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }
`;
const H3style = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: ${theme.colors.Primary_text_clr};
  margin: 0;
`;
const H4style = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.Primary_text_clr};
  margin: 0;
`;
const H5style = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: ${theme.colors.Primary_text_clr};
  margin: 0;
`;
const ParaStyle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.Secondary_text_clr};
  margin: 0;
`;
const MediumText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.colors.Primary_text_clr};
  margin: 0;
  text-align: center;
`;
const SmallText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0;
`;

/* You are in exported area. */
export default {
  Container,
  Row,
  Flexbox,
  Contentbox,
  H1style,
  H2style,
  H3style,
  H4style,
  H5style,
  ParaStyle,
  MediumText,
  SmallText
};
