import * as actions from '../../actions/types';

const initialState = {
  subjects: undefined,
  channels: undefined,
  eventTypes: undefined,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_MISC_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_MISC_SUBJECTS:
      return {
        ...state,
        subjects: action.payload
      };
    case actions.SET_MISC_CHANNELS:
      const channels = action.payload;
      return {
        ...state,
        channels: channels
      };
    case actions.SET_MISC_EVENTTYPES:
      return {
        ...state,
        eventTypes: action.payload
      };
    default:
      return state;
  }
}