import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { checkEventLiveStatus } from '../../services/eventService';
import { getNameById, isEmpty, removeWhiteSpaceRegex } from '../../global/Helpers';
import Icon from '../../assets/images/Vectoricon.svg';
import IconOne from '../../assets/images/vectoricon-one.svg';
import Countdown, { zeroPad } from 'react-countdown';

const Box = styled.div`
  padding: 10px;
  max-width: 25%;
  flex: 0 1 25%;
  @media only screen and (max-width: 1023px) {
    max-width: 33.33%;
    flex: 0 1 33.33%;
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    flex: 0 1 100%;
  }
`;
const BoxUpdate = styled.div`
  flex: 0 0 25%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  :hover .blockicon {
    display: block;
  }
`;
const LabelBoxWrapper = styled.div``;
const LabelBox = styled.div`
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 2px 8px 0px 4px;
  background-color: #d71515;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  ::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid #d71515;
    border-left: 20px solid transparent;
    position: absolute;
    right: 100%;
    top: 0;
  }
`;
const LabelBoxCountDown = styled.div`
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 3px 9px 3px 8px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  ::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 18px solid rgba(0, 0, 0, 0.5);
    border-left: 18px solid transparent;
    position: absolute;
    right: 100%;
    top: 0;
  }
`;
const ImgBolshoi = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  left: -999px;
  right: -999px;
  top: -999px;
  bottom: -999px;
  margin: auto;
`;
const ImgBox = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 54.25%;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
`;
const ImageBolshoi = styled.img`
  position: absolute;
  right: 15px;
  top: -10%;
  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 40px;
`;
const BoxDetail = styled.div`
  padding: 15px 15px 35px 15px;
  flex: 1;
  position: relative;
`;
const Attribute = styled.p`
  background: #2a2b34;
  border-radius: 20px;
  padding: 2px 12px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 18px;
`;
const DetailsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
const DetailsLake = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
const Details = styled.span`
  margin-bottom: 15px;
  margin-top: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
`;
const TimeDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 0px 15px 15px 15px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;
const TimeText = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
`;
const DateText = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
`;
const BlockIcon = styled.div`
  z-index: 9;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 38px;
  height: 38px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BlockIconWrapper = styled.div``;
const ImgB = styled.img``;

export class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiveEvent: false
    };
  }

  componentDidMount() {
    this.checkEventStatus();
  }

  onItemClicked = () => {
    if (this.props.onItemClicked) this.props.onItemClicked();
  };

  onPublisherNameClicked = () => {
    const { item } = this.props;
    const publisher = removeWhiteSpaceRegex(item.publisher);
    if (this.props.onPublisherNameClicked) this.props.onPublisherNameClicked(publisher);
  };

  checkEventStatus = () => {
    const { item } = this.props;
    const isLiveEvent = checkEventLiveStatus(item);
    this.setState({ isLiveEvent });
  };

  onWatchListIconClicked = (e) => {
    e.stopPropagation();
    if (this.props.onWatchListIconClicked) this.props.onWatchListIconClicked();
  };

  // Renderer callback with condition
  renderCountDown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return null;
    } else {
      // Render a countdown
      return (
        <>
          <span>
            {days}D : {hours}H : {minutes}M
          </span>
        </>
      );
    }
  };

  onCompleteCountDown = () => {
    this.setState({ isLiveEvent: true });
  };

  render() {
    const { item, isWatchListItem, eventTypes } = this.props;
    const { isLiveEvent } = this.state;
    return (
      <Box>
        <BoxUpdate>
          {isLiveEvent ? (
            <LabelBoxWrapper>
              <LabelBox>LIVE</LabelBox>
            </LabelBoxWrapper>
          ) : (
              <LabelBoxWrapper>
                <LabelBoxCountDown>
                  <Countdown
                    date={new Date(item.startTime)}
                    renderer={this.renderCountDown}
                    onComplete={this.onCompleteCountDown}
                  />
                </LabelBoxCountDown>
              </LabelBoxWrapper>
            )}
          <ImgBox onClick={this.onItemClicked}>
            <BlockIconWrapper className='blockicon'>
              <BlockIcon onClick={this.onWatchListIconClicked}>
                <ImgB src={!isWatchListItem ? Icon : IconOne} />
              </BlockIcon>
            </BlockIconWrapper>
            <ImgBolshoi className='BolshoiImg' alt='publisher Thumbnail' src={item.publisherThumbnail} />
          </ImgBox>
          <BoxDetail>
            {!isEmpty(item.publisherLogo) && <ImageBolshoi height='40' src={item.publisherLogo} alt='publisher Logo' sizes='100px' />}
            <Attribute>{getNameById(eventTypes, item.eventTypeId)}</Attribute>
            <DetailsWrapper className='line-clamp'>
              <DetailsLake onClick={this.onItemClicked}>{item.eventName}</DetailsLake>
            </DetailsWrapper>
            <Details onClick={this.onPublisherNameClicked}>{item.publisher}</Details>
            <TimeDetails>
              <TimeText>{moment(item.startTime).format('hh:mm a')}</TimeText>
              <DateText>{moment(item.startTime).format('MMM DD, YYYY')}</DateText>
            </TimeDetails>
          </BoxDetail>
        </BoxUpdate>
      </Box>
    );
  }
}

export default ListItem;
