import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Loading from '../../components/Loading/Loading';
import { clearResponseMessage } from '../../actions/messageActions';
import { getUserProfile, updateUserProfile } from '../../services/authService';
import { setCurrentUser, setUserProfile } from '../../actions/authActions';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import globalStyle from '../../assets/universal/globalStyles';
import styled, { css } from 'styled-components';
import Dropzone from 'react-dropzone';
import { uploadImage } from '../../services/eventService';
import Button from '../../components/Button/Button';
import localdataService from '../../services/localdataService';
import { replaceWhiteSpaceWithDash } from '../../global/Helpers';
import Status from '../../components/StatusBox/Status';

const Container = globalStyle.Container;
const Row = globalStyle.Row;
const Contentbox = globalStyle.Contentbox;
const H1style = globalStyle.H1style;
const Form = styled.form``;
const ContentHead = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.48px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
`;
const FormHead = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 6px;
  &.title {
    margin-top: 12px;
  }
`;
const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px -8px;
  @media only screen and (max-width: 767px) {
    display: block;
    margin: 20px -8px 0 -8px;
  }
  &.formgroup {
    display: block;
  }
  h4 {
    padding: 0;
    margin-bottom: 6px;
  }
`;
const FormColFull = styled.div`
  max-width: 100%;
  flex: 0 1 100%;
  padding: 0px 8px;
  &.formcol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0;
  }
  .containersm {
    background: rgba(255, 255, 255, 0.05);
    padding: 6px 6px 6px 10px;
    width: 100%;
    max-width: 74.5%;
    border-radius: 5px;
  }
  &.upload {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 200;
    }
  }
  .btnchoose {
    border: 0;
    margin: 10px 0;
    background: #484955;
    border-radius: 4px;
    padding: 10px 12px;
    color: #fff;
  }
  .uploadimg {
    display: block;
    margin: auto;
  }
`;
const FormColHalf = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0px 8px;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  border: 0;
  padding: 12px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;
const DropZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    padding: 10px 12px;
    background: #484955;
    border-radius: 4px;
    border: 0;
    color: #fff;
  }
  .btnupload {
    background: #ff715b;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    padding: 12px 17px;
    border: 0;
    color: #fff;
  }
`;
const DropZoneDiv = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  p {
    margin-left: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50%;
  }
`;
const ProfileWrapper = styled.div`
  max-width: 500px;
  margin: auto;
`;

const FormWrapper = styled.div`
  margin-top: 60px;
`;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.LoadData();
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  LoadData = async () => {
    await this.props.getUserProfile();
  };

  onChangeInput = (property, value) => {
    const { userProfile } = this.props.auth;
    if (userProfile) {
      userProfile[property] = value;
    }
    this.props.setUserProfile(userProfile);
  };

  onNameChange = (e) => {
    this.onChangeInput('displayName', e.target.value);
  };

  onEmailChange = (e) => {
    this.onChangeInput('email', e.target.value);
  };

  handleFormSubmission = async (e) => {
    e.preventDefault();
    const { userProfile } = this.props.auth;

    const profileObj = {
      displayName: userProfile.displayName
    };
    const result = await this.props.updateUserProfile(profileObj);
    if (result) {
      const user = localdataService.getCurrentUser();
      user.displayName = result.displayName;
      user.profile_url = result.profile_url;
      localdataService.setCurrentUser(user);
      this.props.setCurrentUser(user);
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  onChangeProfilePhoto = (event) => {
    this.readFile(event.target.files[0], 'profile_url');
  };

  readFile = (file, key) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      let ImageData = reader.result;
      if (ImageData) {
        const splitData = ImageData.split(',');
        if (splitData && splitData.length > 1) ImageData = splitData[1];
        const upload = {
          name: replaceWhiteSpaceWithDash(file.name),
          contentType: file.type,
          file: ImageData
        };
        const result = await this.props.uploadImage(upload);
        if (result) {
          this.onChangeInput(key, result.url);
          await this.props.updateUserProfile({ profile_url: result.url });
          const user = localdataService.getCurrentUser();
          user.profile_url = result.url;
          localdataService.setCurrentUser(user);
          this.props.setCurrentUser(user);
        }
      }
    };
  };

  render() {
    const { loading, userProfile } = this.props.auth;
    const { displayName, profile_url } = userProfile;
    if (loading && (!userProfile || !userProfile.displayName)) return <Loading />;

    return (
      <>
        <Header history={this.props.history} />
        <Container>
          <Row>
            <Form onSubmit={this.handleFormSubmission}>
              <Contentbox>
                <ProfileWrapper>
                  <H1style className='ProfileText'>Profile</H1style>
                  <ContentHead className='ProfileText'>Your Details</ContentHead>
                  <FormWrapper>
                    <FormGroup>
                      <FormColFull>
                        <FormHead first>Full Name</FormHead>
                        <Input
                          type='text'
                          id='name'
                          name='name'
                          placeholder='enter your name'
                          value={displayName}
                          onChange={this.onNameChange}
                        />
                      </FormColFull>
                    </FormGroup>
                    <FormColFull className='formcol Mt-top'>
                      <div className='upload_preview'>{profile_url && <img src={profile_url} alt='logo' />}</div>
                      <Dropzone
                        ref={this.publisherLogoRef}
                        noClick
                        noKeyboard
                        accept='image/*'
                        multiple={false}
                        onDrop={(accepted, rejected) => this.onDropPublisherLogo(accepted, rejected)}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                          return (
                            <div className='container containersm '>
                              <DropZone {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <DropZoneDiv>
                                  {/* <button className='btn' type="button" onClick={this.openPublisherLogoDialog}>Choose image to upload</button> */}
                                  <label htmlFor='profile_url' className='btn'>
                                    Choose image to upload
                                  </label>
                                  <input
                                    id='profile_url'
                                    style={{ display: 'none' }}
                                    type='file'
                                    onChange={this.onChangeProfilePhoto}
                                    accept='image/*'
                                  />
                                  {acceptedFiles && acceptedFiles.length > 0 && <p>{acceptedFiles[0].name}</p>}
                                </DropZoneDiv>
                                {/* <div className={classNames("btn-save-loading", { 'loading': loading })}>
                            <button className="btnupload btn base_btn" type={"button"} onClick={this.onUploadPublisherLogo}>Upload your logo</button>
                          </div> */}
                              </DropZone>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </FormColFull>
                  </FormWrapper>
                </ProfileWrapper>
              </Contentbox>
              {this.renderErrors()}
              {this.renderSuccess()}
              <div className='btn-submit'>
                <div className={classNames('btn-save-loading', { loading: loading })}>
                  <Button type='submit' title='Submit' isPrimary={true} className='btn base_btn' />
                </div>
              </div>
            </Form>
          </Row>
        </Container>

        <Footer history={this.props.history} />
      </>
    );
  }
}

Profile.propTypes = {
  message: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getUserProfile,
  setUserProfile,
  updateUserProfile,
  uploadImage,
  setCurrentUser
})(Profile);
