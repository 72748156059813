import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Attributes = styled.a`
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  margin: 0 7px;
  cursor: pointer;
  display: inline-block;
  overflow-y: auto;
  &.active {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.5);
  }
`;
class SubjectListItem extends Component {
  setSubject = (id) => {
    if (!id) return;
    if (this.props.onSubjectChange) this.props.onSubjectChange(id);
  };

  render() {
    const { item, isSelected } = this.props;
    return (
      <Attributes
        className={classNames({ active: !!isSelected })}
        onClick={() => {
          this.setSubject(item.id);
        }}>
        {item.name}
      </Attributes>
    );
  }
}

export default SubjectListItem;
