

const countries = [
  { value: 'AF', text: 'Afghanistan' },
  { value: 'AX', text: 'Åland Islands' },
  { value: 'AL', text: 'Albania' },
  { value: 'DZ', text: 'Algeria' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'AD', text: 'AndorrA' },
  { value: 'AO', text: 'Angola' },
  { value: 'AI', text: 'Anguilla' },
  { value: 'AQ', text: 'Antarctica' },
  { value: 'AG', text: 'Antigua and Barbuda' },
  { value: 'AR', text: 'Argentina' },
  { value: 'AM', text: 'Armenia' },
  { value: 'AW', text: 'Aruba' },
  { value: 'AU', text: 'Australia' },
  { value: 'AT', text: 'Austria' },
  { value: 'AZ', text: 'Azerbaijan' },
  { value: 'BS', text: 'Bahamas' },
  { value: 'BH', text: 'Bahrain' },
  { value: 'BD', text: 'Bangladesh' },
  { value: 'BB', text: 'Barbados' },
  { value: 'BY', text: 'Belarus' },
  { value: 'BE', text: 'Belgium' },
  { value: 'BZ', text: 'Belize' },
  { value: 'BJ', text: 'Benin' },
  { value: 'BM', text: 'Bermuda' },
  { value: 'BT', text: 'Bhutan' },
  { value: 'BO', text: 'Bolivia' },
  { value: 'BA', text: 'Bosnia and Herzegovina' },
  { value: 'BW', text: 'Botswana' },
  { value: 'BV', text: 'Bouvet Island' },
  { value: 'BR', text: 'Brazil' },
  { value: 'IO', text: 'British Indian Ocean Territory' },
  { value: 'BN', text: 'Brunei Darussalam' },
  { value: 'BG', text: 'Bulgaria' },
  { value: 'BF', text: 'Burkina Faso' },
  { value: 'BI', text: 'Burundi' },
  { value: 'KH', text: 'Cambodia' },
  { value: 'CM', text: 'Cameroon' },
  { value: 'CA', text: 'Canada' },
  { value: 'CV', text: 'Cape Verde' },
  { value: 'KY', text: 'Cayman Islands' },
  { value: 'CF', text: 'Central African Republic' },
  { value: 'TD', text: 'Chad' },
  { value: 'CL', text: 'Chile' },
  { value: 'CN', text: 'China' },
  { value: 'CX', text: 'Christmas Island' },
  { value: 'CC', text: 'Cocos (Keeling) Islands' },
  { value: 'CO', text: 'Colombia' },
  { value: 'KM', text: 'Comoros' },
  { value: 'CG', text: 'Congo' },
  { value: 'CD', text: 'Congo, The Democratic Republic of the' },
  { value: 'CK', text: 'Cook Islands' },
  { value: 'CR', text: 'Costa Rica' },
  { value: 'CI', text: "Cote D'Ivoire" },
  { value: 'HR', text: 'Croatia' },
  { value: 'CU', text: 'Cuba' },
  { value: 'CY', text: 'Cyprus' },
  { value: 'CZ', text: 'Czech Republic' },
  { value: 'DK', text: 'Denmark' },
  { value: 'DJ', text: 'Djibouti' },
  { value: 'DM', text: 'Dominica' },
  { value: 'DO', text: 'Dominican Republic' },
  { value: 'EC', text: 'Ecuador' },
  { value: 'EG', text: 'Egypt' },
  { value: 'SV', text: 'El Salvador' },
  { value: 'GQ', text: 'Equatorial Guinea' },
  { value: 'ER', text: 'Eritrea' },
  { value: 'EE', text: 'Estonia' },
  { value: 'ET', text: 'Ethiopia' },
  { value: 'FK', text: 'Falkland Islands (Malvinas)' },
  { value: 'FO', text: 'Faroe Islands' },
  { value: 'FJ', text: 'Fiji' },
  { value: 'FI', text: 'Finland' },
  { value: 'FR', text: 'France' },
  { value: 'GF', text: 'French Guiana' },
  { value: 'PF', text: 'French Polynesia' },
  { value: 'TF', text: 'French Southern Territories' },
  { value: 'GA', text: 'Gabon' },
  { value: 'GM', text: 'Gambia' },
  { value: 'GE', text: 'Georgia' },
  { value: 'DE', text: 'Germany' },
  { value: 'GH', text: 'Ghana' },
  { value: 'GI', text: 'Gibraltar' },
  { value: 'GR', text: 'Greece' },
  { value: 'GL', text: 'Greenland' },
  { value: 'GD', text: 'Grenada' },
  { value: 'GP', text: 'Guadeloupe' },
  { value: 'GU', text: 'Guam' },
  { value: 'GT', text: 'Guatemala' },
  { value: 'GG', text: 'Guernsey' },
  { value: 'GN', text: 'Guinea' },
  { value: 'GW', text: 'Guinea-Bissau' },
  { value: 'GY', text: 'Guyana' },
  { value: 'HT', text: 'Haiti' },
  { value: 'HM', text: 'Heard Island and Mcdonald Islands' },
  { value: 'VA', text: 'Holy See (Vatican City State)' },
  { value: 'HN', text: 'Honduras' },
  { value: 'HK', text: 'Hong Kong' },
  { value: 'HU', text: 'Hungary' },
  { value: 'IS', text: 'Iceland' },
  { value: 'IN', text: 'India' },
  { value: 'value', text: 'Indonesia' },
  { value: 'IR', text: 'Iran, Islamic Republic Of' },
  { value: 'IQ', text: 'Iraq' },
  { value: 'IE', text: 'Ireland' },
  { value: 'IM', text: 'Isle of Man' },
  { value: 'IL', text: 'Israel' },
  { value: 'IT', text: 'Italy' },
  { value: 'JM', text: 'Jamaica' },
  { value: 'JP', text: 'Japan' },
  { value: 'JE', text: 'Jersey' },
  { value: 'JO', text: 'Jordan' },
  { value: 'KZ', text: 'Kazakhstan' },
  { value: 'KE', text: 'Kenya' },
  { value: 'KI', text: 'Kiribati' },
  { value: 'KP', text: "Korea, Democratic People'S Republic of" },
  { value: 'KR', text: 'Korea, Republic of' },
  { value: 'KW', text: 'Kuwait' },
  { value: 'KG', text: 'Kyrgyzstan' },
  { value: 'LA', text: "Lao People'S Democratic Republic" },
  { value: 'LV', text: 'Latvia' },
  { value: 'LB', text: 'Lebanon' },
  { value: 'LS', text: 'Lesotho' },
  { value: 'LR', text: 'Liberia' },
  { value: 'LY', text: 'Libyan Arab Jamahiriya' },
  { value: 'LI', text: 'Liechtenstein' },
  { value: 'LT', text: 'Lithuania' },
  { value: 'LU', text: 'Luxembourg' },
  { value: 'MO', text: 'Macao' },
  { value: 'MK', text: 'Macedonia, The Former Yugoslav Republic of' },
  { value: 'MG', text: 'Madagascar' },
  { value: 'MW', text: 'Malawi' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'MV', text: 'Maldives' },
  { value: 'ML', text: 'Mali' },
  { value: 'MT', text: 'Malta' },
  { value: 'MH', text: 'Marshall Islands' },
  { value: 'MQ', text: 'Martinique' },
  { value: 'MR', text: 'Mauritania' },
  { value: 'MU', text: 'Mauritius' },
  { value: 'YT', text: 'Mayotte' },
  { value: 'MX', text: 'Mexico' },
  { value: 'FM', text: 'Micronesia, Federated States of' },
  { value: 'MD', text: 'Moldova, Republic of' },
  { value: 'MC', text: 'Monaco' },
  { value: 'MN', text: 'Mongolia' },
  { value: 'MS', text: 'Montserrat' },
  { value: 'MA', text: 'Morocco' },
  { value: 'MZ', text: 'Mozambique' },
  { value: 'MM', text: 'Myanmar' },
  { value: 'NA', text: 'Namibia' },
  { value: 'NR', text: 'Nauru' },
  { value: 'NP', text: 'Nepal' },
  { value: 'NL', text: 'Netherlands' },
  { value: 'AN', text: 'Netherlands Antilles' },
  { value: 'NC', text: 'New Caledonia' },
  { value: 'NZ', text: 'New Zealand' },
  { value: 'NI', text: 'Nicaragua' },
  { value: 'NE', text: 'Niger' },
  { value: 'NG', text: 'Nigeria' },
  { value: 'NU', text: 'Niue' },
  { value: 'NF', text: 'Norfolk Island' },
  { value: 'MP', text: 'Northern Mariana Islands' },
  { value: 'NO', text: 'Norway' },
  { value: 'OM', text: 'Oman' },
  { value: 'PK', text: 'Pakistan' },
  { value: 'PW', text: 'Palau' },
  { value: 'PS', text: 'Palestinian Territory, Occupied' },
  { value: 'PA', text: 'Panama' },
  { value: 'PG', text: 'Papua New Guinea' },
  { value: 'PY', text: 'Paraguay' },
  { value: 'PE', text: 'Peru' },
  { value: 'PH', text: 'Philippines' },
  { value: 'PN', text: 'Pitcairn' },
  { value: 'PL', text: 'Poland' },
  { value: 'PT', text: 'Portugal' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'QA', text: 'Qatar' },
  { value: 'RE', text: 'Reunion' },
  { value: 'RO', text: 'Romania' },
  { value: 'RU', text: 'Russian Federation' },
  { value: 'RW', text: 'RWANDA' },
  { value: 'SH', text: 'Saint Helena' },
  { value: 'KN', text: 'Saint Kitts and Nevis' },
  { value: 'LC', text: 'Saint Lucia' },
  { value: 'PM', text: 'Saint Pierre and Miquelon' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines' },
  { value: 'WS', text: 'Samoa' },
  { value: 'SM', text: 'San Marino' },
  { value: 'ST', text: 'Sao Tome and Principe' },
  { value: 'SA', text: 'Saudi Arabia' },
  { value: 'SN', text: 'Senegal' },
  { value: 'CS', text: 'Serbia and Montenegro' },
  { value: 'SC', text: 'Seychelles' },
  { value: 'SL', text: 'Sierra Leone' },
  { value: 'SG', text: 'Singapore' },
  { value: 'SK', text: 'Slovakia' },
  { value: 'SI', text: 'Slovenia' },
  { value: 'SB', text: 'Solomon Islands' },
  { value: 'SO', text: 'Somalia' },
  { value: 'ZA', text: 'South Africa' },
  { value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
  { value: 'ES', text: 'Spain' },
  { value: 'LK', text: 'Sri Lanka' },
  { value: 'SD', text: 'Sudan' },
  { value: 'SR', text: 'Suriname' },
  { value: 'SJ', text: 'Svalbard and Jan Mayen' },
  { value: 'SZ', text: 'Swaziland' },
  { value: 'SE', text: 'Sweden' },
  { value: 'CH', text: 'Switzerland' },
  { value: 'SY', text: 'Syrian Arab Republic' },
  { value: 'TW', text: 'Taiwan, Province of China' },
  { value: 'TJ', text: 'Tajikistan' },
  { value: 'TZ', text: 'Tanzania, United Republic of' },
  { value: 'TH', text: 'Thailand' },
  { value: 'TL', text: 'Timor-Leste' },
  { value: 'TG', text: 'Togo' },
  { value: 'TK', text: 'Tokelau' },
  { value: 'TO', text: 'Tonga' },
  { value: 'TT', text: 'Trinvaluead and Tobago' },
  { value: 'TN', text: 'Tunisia' },
  { value: 'TR', text: 'Turkey' },
  { value: 'TM', text: 'Turkmenistan' },
  { value: 'TC', text: 'Turks and Caicos Islands' },
  { value: 'TV', text: 'Tuvalu' },
  { value: 'UG', text: 'Uganda' },
  { value: 'UA', text: 'Ukraine' },
  { value: 'AE', text: 'United Arab Emirates' },
  { value: 'GB', text: 'United Kingdom' },
  { value: 'US', text: 'United States' },
  { value: 'UM', text: 'United States Minor Outlying Islands' },
  { value: 'UY', text: 'Uruguay' },
  { value: 'UZ', text: 'Uzbekistan' },
  { value: 'VU', text: 'Vanuatu' },
  { value: 'VE', text: 'Venezuela' },
  { value: 'VN', text: 'Viet Nam' },
  { value: 'VG', text: 'Virgin Islands, British' },
  { value: 'VI', text: 'Virgin Islands, U.S.' },
  { value: 'WF', text: 'Wallis and Futuna' },
  { value: 'EH', text: 'Western Sahara' },
  { value: 'YE', text: 'Yemen' },
  { value: 'ZM', text: 'Zambia' },
  { value: 'ZW', text: 'Zimbabwe' }
];

const languages = [
  { value: 'ab', text: 'Abkhazian' },
  { value: 'aa', text: 'Afar' },
  { value: 'af', text: 'Afrikaans' },
  { value: 'ak', text: 'Akan' },
  { value: 'sq', text: 'Albanian' },
  { value: 'am', text: 'Amharic' },
  { value: 'ar', text: 'Arabic' },
  { value: 'an', text: 'Aragonese' },
  { value: 'hy', text: 'Armenian' },
  { value: 'as', text: 'Assamese' },
  { value: 'av', text: 'Avaric' },
  { value: 'ae', text: 'Avestan' },
  { value: 'ay', text: 'Aymara' },
  { value: 'az', text: 'Azerbaijani' },
  { value: 'bm', text: 'Bambara' },
  { value: 'ba', text: 'Bashkir' },
  { value: 'eu', text: 'Basque' },
  { value: 'be', text: 'Belarusian' },
  { value: 'bn', text: 'Bengali (Bangla)' },
  { value: 'bh', text: 'Bihari' },
  { value: 'bi', text: 'Bislama' },
  { value: 'bs', text: 'Bosnian' },
  { value: 'br', text: 'Breton' },
  { value: 'bg', text: 'Bulgarian' },
  { value: 'my', text: 'Burmese' },
  { value: 'ca', text: 'Catalan' },
  { value: 'ch', text: 'Chamorro' },
  { value: 'ce', text: 'Chechen' },
  { value: 'ny', text: 'Chichewa, Chewa, Nyanja' },
  { value: 'zh', text: 'Chinese' },
  { value: 'zh-Hans', text: 'Chinese (Simplified)' },
  { value: 'zh-Hant', text: 'Chinese (Traditional)' },
  { value: 'cv', text: 'Chuvash' },
  { value: 'kw', text: 'Cornish' },
  { value: 'co', text: 'Corsican' },
  { value: 'cr', text: 'Cree' },
  { value: 'hr', text: 'Croatian' },
  { value: 'cs', text: 'Czech' },
  { value: 'da', text: 'Danish' },
  { value: 'dv', text: 'Divehi, Dhivehi, Maldivian' },
  { value: 'nl', text: 'Dutch' },
  { value: 'dz', text: 'Dzongkha' },
  { value: 'en', text: 'English' },
  { value: 'eo', text: 'Esperanto' },
  { value: 'et', text: 'Estonian' },
  { value: 'ee', text: 'Ewe' },
  { value: 'fo', text: 'Faroese' },
  { value: 'fj', text: 'Fijian' },
  { value: 'fi', text: 'Finnish' },
  { value: 'fr', text: 'French' },
  { value: 'ff', text: 'Fula, Fulah, Pulaar, Pular' },
  { value: 'gl', text: 'Galician' },
  { value: 'gd', text: 'Gaelic (Scottish)' },
  { value: 'gv', text: 'Gaelic (Manx)' },
  { value: 'ka', text: 'Georgian' },
  { value: 'de', text: 'German' },
  { value: 'el', text: 'Greek' },
  { value: 'kl', text: 'Greenlandic' },
  { value: 'gn', text: 'Guarani' },
  { value: 'gu', text: 'Gujarati' },
  { value: 'ht', text: 'Haitian Creole' },
  { value: 'ha', text: 'Hausa' },
  { value: 'he', text: 'Hebrew' },
  { value: 'hz', text: 'Herero' },
  { value: 'hi', text: 'Hindi' },
  { value: 'ho', text: 'Hiri Motu' },
  { value: 'hu', text: 'Hungarian' },
  { value: 'is', text: 'Icelandic' },
  { value: 'io', text: 'valueo' },
  { value: 'ig', text: 'Igbo' },
  { value: 'value', text: 'Indonesian' },
  { value: 'ia', text: 'Interlingua' },
  { value: 'ie', text: 'Interlingue' },
  { value: 'iu', text: 'Inuktitut' },
  { value: 'ik', text: 'Inupiak' },
  { value: 'ga', text: 'Irish' },
  { value: 'it', text: 'Italian' },
  { value: 'ja', text: 'Japanese' },
  { value: 'jv', text: 'Javanese' },
  { value: 'kl', text: 'Kalaallisut, Greenlandic' },
  { value: 'kn', text: 'Kannada' },
  { value: 'kr', text: 'Kanuri' },
  { value: 'ks', text: 'Kashmiri' },
  { value: 'kk', text: 'Kazakh' },
  { value: 'km', text: 'Khmer' },
  { value: 'ki', text: 'Kikuyu' },
  { value: 'rw', text: 'Kinyarwanda (Rwanda)' },
  { value: 'rn', text: 'Kirundi' },
  { value: 'ky', text: 'Kyrgyz' },
  { value: 'kv', text: 'Komi' },
  { value: 'kg', text: 'Kongo' },
  { value: 'ko', text: 'Korean' },
  { value: 'ku', text: 'Kurdish' },
  { value: 'kj', text: 'Kwanyama' },
  { value: 'lo', text: 'Lao' },
  { value: 'la', text: 'Latin' },
  { value: 'lv', text: 'Latvian (Lettish)' },
  { value: 'li', text: 'Limburgish ( Limburger)' },
  { value: 'ln', text: 'Lingala' },
  { value: 'lt', text: 'Lithuanian' },
  { value: 'lu', text: 'Luga-Katanga' },
  { value: 'lg', text: 'Luganda, Ganda' },
  { value: 'lb', text: 'Luxembourgish' },
  { value: 'gv', text: 'Manx' },
  { value: 'mk', text: 'Macedonian' },
  { value: 'mg', text: 'Malagasy' },
  { value: 'ms', text: 'Malay' },
  { value: 'ml', text: 'Malayalam' },
  { value: 'mt', text: 'Maltese' },
  { value: 'mi', text: 'Maori' },
  { value: 'mr', text: 'Marathi' },
  { value: 'mh', text: 'Marshallese' },
  { value: 'mo', text: 'Moldavian' },
  { value: 'mn', text: 'Mongolian' },
  { value: 'na', text: 'Nauru' },
  { value: 'nv', text: 'Navajo' },
  { value: 'ng', text: 'Ndonga' },
  { value: 'nd', text: 'Northern Ndebele' },
  { value: 'ne', text: 'Nepali' },
  { value: 'no', text: 'Norwegian' },
  { value: 'nb', text: 'Norwegian bokmål' },
  { value: 'nn', text: 'Norwegian nynorsk' },
  { value: 'ii', text: 'Nuosu' },
  { value: 'oc', text: 'Occitan' },
  { value: 'oj', text: 'Ojibwe' },
  { value: 'cu', text: 'Old Church Slavonic, Old Bulgarian' },
  { value: 'or', text: 'Oriya' },
  { value: 'om', text: 'Oromo (Afaan Oromo)' },
  { value: 'os', text: 'Ossetian' },
  { value: 'pi', text: 'Pāli' },
  { value: 'ps', text: 'Pashto, Pushto' },
  { value: 'fa', text: 'Persian (Farsi)' },
  { value: 'pl', text: 'Polish' },
  { value: 'pt', text: 'Portuguese' },
  { value: 'pa', text: 'Punjabi (Eastern)' },
  { value: 'qu', text: 'Quechua' },
  { value: 'rm', text: 'Romansh' },
  { value: 'ro', text: 'Romanian' },
  { value: 'ru', text: 'Russian' },
  { value: 'se', text: 'Sami' },
  { value: 'sm', text: 'Samoan' },
  { value: 'sg', text: 'Sango' },
  { value: 'sa', text: 'Sanskrit' },
  { value: 'sr', text: 'Serbian' },
  { value: 'sh', text: 'Serbo-Croatian' },
  { value: 'st', text: 'Sesotho' },
  { value: 'tn', text: 'Setswana' },
  { value: 'sn', text: 'Shona' },
  // { value: 'ii', text: 'Sichuan Yi' },
  { value: 'sd', text: 'Sindhi' },
  { value: 'si', text: 'Sinhalese' },
  { value: 'ss', text: 'Siswati' },
  { value: 'sk', text: 'Slovak' },
  { value: 'sl', text: 'Slovenian' },
  { value: 'so', text: 'Somali' },
  { value: 'nr', text: 'Southern Ndebele' },
  { value: 'es', text: 'Spanish' },
  { value: 'su', text: 'Sundanese' },
  { value: 'sw', text: 'Swahili (Kiswahili)' },
  { value: 'ss', text: 'Swati' },
  { value: 'sv', text: 'Swedish' },
  { value: 'tl', text: 'Tagalog' },
  { value: 'ty', text: 'Tahitian' },
  { value: 'tg', text: 'Tajik' },
  { value: 'ta', text: 'Tamil' },
  { value: 'tt', text: 'Tatar' },
  { value: 'te', text: 'Telugu' },
  { value: 'th', text: 'Thai' },
  { value: 'bo', text: 'Tibetan' },
  { value: 'ti', text: 'Tigrinya' },
  { value: 'to', text: 'Tonga' },
  { value: 'ts', text: 'Tsonga' },
  { value: 'tr', text: 'Turkish' },
  { value: 'tk', text: 'Turkmen' },
  { value: 'tw', text: 'Twi' },
  { value: 'ug', text: 'Uyghur' },
  { value: 'uk', text: 'Ukrainian' },
  { value: 'ur', text: 'Urdu' },
  { value: 'uz', text: 'Uzbek' },
  { value: 've', text: 'Venda' },
  { value: 'vi', text: 'Vietnamese' },
  { value: 'vo', text: 'Volapük' },
  { value: 'wa', text: 'Wallon' },
  { value: 'cy', text: 'Welsh' },
  { value: 'wo', text: 'Wolof' },
  { value: 'fy', text: 'Western Frisian' },
  { value: 'xh', text: 'Xhosa' },
  { value: 'yi', text: 'Yvaluedish' },
  { value: 'yo', text: 'Yoruba' },
  { value: 'za', text: 'Zhuang, Chuang' },
  { value: 'zu', text: 'Zulu' }
];

export default { countries, languages };
