import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { signup } from '../../../services/authService';
import { clearResponseMessage, setErrorMessage } from '../../../actions/messageActions';
import Status from '../../../components/StatusBox/Status';
import localdataService from '../../../services/localdataService';
import AuthLayout from '../AuthLayout';
import classNames from 'classnames';
import { Dialog } from '@progress/kendo-react-dialogs';
import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';

const AuthBoxDetailWrapper = styled.div`
  width: 100%;
  p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      color: #fe715b;
      cursor: pointer;
    }
  }
`;
const AuthBoxDetail = styled.div`
  text-align: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
  .h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: -1.08px;
    color: #ffffff;
    margin-bottom: 5px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthForm = styled.form`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const FormColFull = styled.div`
  width: 100%;
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 0;
  padding: 14px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  ::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.3);
  }
`;
const ContentHead = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  margin-top: 20px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const CheckBox = styled.div`
  margin: 30px 0 40px 0;
  display: flex;
  @media only screen and (max-width: 1365px) {
    margin: 30px 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 30px 0 0 0;
  }
`;
const ButtonBox = styled.div`
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
`;
const Button = styled.button`
  background: #ff715b;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
  border: 0;
  border-radius: 4px;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.05)')};
  outline: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 150ms;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const Label = styled.label`
  display: flex;
`;
const LabelText = styled.p`
  margin-left: 15px;
  @media only screen and (max-width: 1023px) {
    margin-left: 3px;
  }
  span {
    color: #fe715b;
  }
`;

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      isAcceptTerms: true,
      isTermsModal: false,
      isPrivacyModal: false
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleNameInputChange = (e) => {
    const name = e.target.value;
    this.setState({ name });
  };

  handleEmailInputChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  handlePasswordInputChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  };

  handleConfirmPasswordInputChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState({ confirmPassword });
  };

  handleAcceptCheckboxChange = (e) => {
    const isAcceptTerms = e.target.checked;
    this.setState({ isAcceptTerms });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword, isAcceptTerms } = this.state;
    const signupObj = {
      name: name,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    };
    if (isAcceptTerms) {
      const result = await this.props.signup(signupObj);
      if (result === true) {
        const userDetails = localdataService.getCurrentUser();
        if (userDetails && userDetails.verificationRequired) {
          this.props.history.push('/verification-code');
          return;
        }
        this.props.history.push('/home');
      }
    } else {
      this.props.setErrorMessage('Terms of services is not accepted');
    }
  };

  onLoginClick = () => {
    this.props.history.push('/login');
  };

  onOpenTermsModal = (e) => {
    e.preventDefault();
    this.onToggleTermsModal();
  };

  onToggleTermsModal = () => {
    this.setState({ isTermsModal: !this.state.isTermsModal });
  };

  onOpenPrivacyModal = (e) => {
    e.preventDefault();
    this.onTogglePrivacyModal();
  };

  onTogglePrivacyModal = () => {
    this.setState({ isPrivacyModal: !this.state.isPrivacyModal });
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { name, email, password, confirmPassword, isAcceptTerms, isTermsModal, isPrivacyModal } = this.state;
    return (
      <>
        <AuthLayout>
          <AuthBoxDetailWrapper>
            <AuthBoxDetail>
              <h2 className='h2'>Create Account</h2>
              <span>Please fill in this form to create an account!</span>
            </AuthBoxDetail>
            <AuthForm onSubmit={this.onSubmit}>
              <FormColFull>
                <ContentHead first>Your Name</ContentHead>
                <Input
                  type='text'
                  placeholder='enter your name'
                  autocomplete='off'
                  maxLength='256'
                  name='name'
                  required=''
                  value={name}
                  onChange={this.handleNameInputChange}
                />
              </FormColFull>
              <FormColFull>
                <ContentHead>Email</ContentHead>
                <Input
                  type='email'
                  placeholder='enter your email address'
                  autocomplete='off'
                  maxLength='256'
                  name='email'
                  required=''
                  value={email}
                  onChange={this.handleEmailInputChange}
                />
              </FormColFull>
              <FormColFull>
                <ContentHead>Password</ContentHead>
                <Input
                  type='password'
                  placeholder='enter password'
                  autocomplete='off'
                  maxLength='256'
                  name='password'
                  required=''
                  value={password}
                  onChange={this.handlePasswordInputChange}
                />
              </FormColFull>
              <FormColFull>
                <ContentHead>Confirm Password</ContentHead>
                <Input
                  type='password'
                  placeholder='enter confirm password'
                  autocomplete='off'
                  maxLength='256'
                  name='confirmPassword'
                  required=''
                  value={confirmPassword}
                  onChange={this.handleConfirmPasswordInputChange}
                />
              </FormColFull>
              <CheckBox>
                <div>
                  <Label>
                    <CheckboxContainer>
                      <HiddenCheckbox
                        checked={isAcceptTerms}
                        value={isAcceptTerms}
                        onChange={this.handleAcceptCheckboxChange}
                      />
                      <StyledCheckbox checked={isAcceptTerms}>
                        {isAcceptTerms && (
                          <Icon className='icon' viewBox='0 0 24 24'>
                            <polyline points='20 6 9 17 4 12' />
                          </Icon>
                        )}
                      </StyledCheckbox>
                    </CheckboxContainer>
                    <LabelText>
                      I accept the <span onClick={this.onOpenTermsModal}>Terms of use </span>&
                      <span onClick={this.onOpenPrivacyModal}> Privacy Policy.</span>
                    </LabelText>
                  </Label>
                </div>
              </CheckBox>
              {this.renderErrors()}
              {this.renderSuccess()}
              <ButtonBox className={classNames('btn-save-loading', { loading: loading })}>
                <Button type='submit' disabled={loading} className='btn base_btn'>
                  Create Account
                </Button>
              </ButtonBox>
            </AuthForm>
            <p>
              Already have an account?{' '}
              <a onClick={this.onLoginClick} disabled={loading}>
                Sign In
              </a>
            </p>
          </AuthBoxDetailWrapper>
        </AuthLayout>

        {isTermsModal && (
          <Dialog
            className='dialogBox primary-DialogBox'
            height={669}
            title='Terms of use'
            onClose={this.onToggleTermsModal}>
            <TermsModal onClose={this.onToggleTermsModal} />
          </Dialog>
        )}

        {isPrivacyModal && (
          <Dialog
            className='dialogBox primary-DialogBox'
            height={669}
            title='Privacy Policy'
            onClose={this.onTogglePrivacyModal}>
            <PrivacyModal onClose={this.onTogglePrivacyModal} />
          </Dialog>
        )}
      </>
    );
  }
}

Signup.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  signup,
  clearResponseMessage,
  setErrorMessage
})(Signup);
