import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import globalStyle from '../../assets/universal/globalStyles';
import styled from 'styled-components';
import Loading from '../../components/Loading/Loading';
import { isEmpty } from '../../global/Helpers';
import { TYPE_ALL, SUBJECT, EVENT_TYPE } from '../../global/constant';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';
import { createEvent, fetchIPLocation, getEventList, uploadImage } from '../../services/eventService';
import ListItem from '../Home/ListItem';
import { getUserWatchList, onToggleWatchListEvent } from '../../services/watchListService';
import { setWatchListEvents } from '../../actions/watchListActions';

const Container = globalStyle.Container;
const Row = globalStyle.Row;
const Contentbox = globalStyle.Contentbox;
const H1style = globalStyle.H1style;
const Boxwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px -10px 0 -10px;
`;

class WatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: []
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
    this.loadData();
  }

  loadData = async () => {
    let eventList = await this.props.getEventList();
    if (eventList) {
      await this.onSubjectChange();
      await this.onChannelChange();
      eventList = this.props.home.eventList;
      const watchList = await this.props.getUserWatchList();
      const watchListEvents = [];
      if (watchList && eventList) {
        // get event item from event list based on watch list event id
        eventList.forEach((x) => {
          watchList.forEach((y) => {
            if (x.id === y.eventId) {
              watchListEvents.push(x);
            }
          });
        });
      }
      this.props.setWatchListEvents(watchListEvents);

      let newList = watchListEvents || this.state.filteredList;
      this.setState({ filteredList: newList });
    }
  };

  onSubjectChange = async (subjectId, channelId) => {
    if (!channelId) channelId = TYPE_ALL;
    if (!subjectId) subjectId = TYPE_ALL;
    const list = this.filterBy(subjectId, SUBJECT);
    this.setState({ filteredList: list, subjectId, channelId });
  };

  onChannelChange = async (channelId, subjectId) => {
    if (!subjectId) subjectId = TYPE_ALL;
    if (!channelId) channelId = TYPE_ALL;
    const list = this.filterBy(channelId, EVENT_TYPE);
    this.setState({ filteredList: list, subjectId, channelId });
  };

  filterBy = (id, param) => {
    const { watchListEvents } = this.props.watchList;
    let list = [];
    if (id === TYPE_ALL) {
      list = watchListEvents;
    } else {
      list = watchListEvents.filter((x) => String(x[param]).toLowerCase() === String(id).toLowerCase());
    }
    return list;
  };

  onSearch = (searchText) => {
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      await this.onSubjectChange();
      await this.onChannelChange();
      this.filterEvents(searchText);
    }, 1000);
  };

  filterEvents = (searchText) => {
    const { watchListEvents } = this.props.watchList;
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    if (!isEmpty(searchText)) {
      newList = watchListEvents.filter(
        (x) =>
          (x.eventName && x.eventName.trim().toLowerCase().includes(searchText)) ||
          (x.subject && x.subject.trim().toLowerCase().includes(searchText))
      );
    } else {
      newList = watchListEvents;
    }
    this.setState({ filteredList: newList });
  };

  onItemClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/event-detail/${id}`);
  };

  onPublisherNameClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/publisher-detail/${id}`);
  };

  onEventAdded = () => {
    this.loadData();
  };

  onToggleWatchList = async (eventId) => {
    if (!eventId) return;

    const result = await this.props.onToggleWatchListEvent(eventId);
    if (result) {
      this.loadData();
    }
  };

  render() {
    const loading_home = this.props.home.loading;
    const loading_watchList = this.props.watchList.loading;
    const loading = loading_home || loading_watchList;
    const { watchListEvents } = this.props.watchList;
    const { filteredList, subjectId, channelId } = this.state;
    const { eventTypes } = this.props.misc;
    if (loading && !watchListEvents) {
      return <Loading />;
    }
    return (
      <>
        <Header
          subjectId={subjectId}
          channelId={channelId}
          onSubjectChange={this.onSubjectChange}
          onEventTypeChange={this.onChannelChange}
          onSearchChange={this.onSearch}
          history={this.props.history}
          onEventAdded={this.onEventAdded}
        />
        <Container>
          <Row>
            <Contentbox>
              <H1style>Watchlist</H1style>
              <Boxwrapper>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item) => {
                    return (
                      <ListItem
                        key={item.id}
                        item={item}
                        isWatchListItem={true}
                        eventTypes={eventTypes}
                        onItemClicked={() => {
                          this.onItemClicked(item.id);
                        }}
                        onWatchListIconClicked={() => {
                          this.onToggleWatchList(item.id);
                        }}
                        onPublisherNameClicked={this.onPublisherNameClicked}
                      />
                    );
                  })}
              </Boxwrapper>
            </Contentbox>
          </Row>
        </Container>

        <Footer subjectId={subjectId} onSubjectChange={this.onSubjectChange} history={this.props.history} />
      </>
    );
  }
}

WatchList.propTypes = {
  message: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  watchList: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  home: state.home,
  watchList: state.watchList,
  auth: state.auth,
  misc: state.misc
});

export default connect(mapStateToProps, {
  createEvent,
  getEventList,
  getUserWatchList,
  setWatchListEvents,
  onToggleWatchListEvent,
  clearResponseMessage,
  setErrorMessage,
  uploadImage,
  fetchIPLocation
})(WatchList);
