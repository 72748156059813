import * as actions from './types';

/**
 * @desc Set content loader
 */
export const setContentLoader = (payload) => {
  return {
    type: actions.SET_CONTENT_LOADER,
    payload: payload
  };
};

/**
 * @desc Set terms content
 */
export const setTermsContent = (payload) => {
  return {
    type: actions.SET_TERMS_CONTENT,
    payload: payload
  };
};

/**
 * @desc Set privacy content
 */
export const setPrivacyContent = (payload) => {
  return {
    type: actions.SET_PRIVACY_CONTENT,
    payload: payload
  };
};
