import EventDetail from './pages/EventDetail/EventDetail';
import Home from './pages/Home/Home';
import ForgotPassword from './pages/Membership/ForgotPassword/ForgotPassword';
import Login from './pages/Membership/Login/Login';
import ResetPassword from './pages/Membership/ResetPassword/ResetPassword';
import Signup from './pages/Membership/Signup/Signup';
import VerificationCode from './pages/Membership/Signup/VerificationCode';
import Profile from './pages/Profile/Profile';
import PublisherDetail from './pages/PublisherDetail/PublisherDetail';
import WatchList from './pages/WatchList/WatchList';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    private: false
  },
  {
    path: '/events/:subjectSlug/:channelSlug',
    exact: true,
    component: Home,
    private: false
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/signup',
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: '/home',
    exact: true,
    component: Home,
    private: false
  },
  {
    path: '/watchList',
    exact: true,
    component: WatchList,
    private: true
  },
  {
    path: '/event-detail/:id',
    exact: true,
    component: EventDetail,
    private: false
  },
  {
    path: '/publisher-detail/:id',
    exact: true,
    component: PublisherDetail,
    private: false
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    private: false
  },
  {
    path: '/auth-action',
    exact: true,
    component: ResetPassword,
    private: false
  },
  {
    path: '/verification-code',
    exact: true,
    component: VerificationCode,
    private: true
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    private: false
  },
];

export default routes;
