import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import globalStyle from '../../assets/universal/globalStyles';
import styled from 'styled-components';
import { getEventList, createEvent, uploadImage, fetchIPLocation } from '../../services/eventService';
import Loading from '../../components/Loading/Loading';
import ListItem from './ListItem';
import SubjectListItem from './SubjectListItem';
import { isEmpty } from '../../global/Helpers';
import { TYPE_ALL } from '../../global/constant';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';
import { getUserWatchList, onToggleWatchListEvent } from '../../services/watchListService';
import { setWatchListEvents } from '../../actions/watchListActions';

const Container = globalStyle.Container;
const Row = globalStyle.Row;
const Contentbox = globalStyle.Contentbox;
const H1style = globalStyle.H1style;
const H2style = globalStyle.H2style;
const SubTitle = styled(H2style)`
  margin-top: 15px;
  flex: 1;
  margin-right: 15px;
`;
const DisplaySubtext = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// const FilterButton = styled.button``;
// const SvgIcon = styled.svg``;
const Boxwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;
const ButtonWrapper = styled.div`
  margin: 50px 0 40px 0;
  overflow: auto;
  white-space: nowrap;
`;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      selectedSubject: this.props.match.params.subjectSlug || 'all',
      selectedChannel: this.props.match.params.channelSlug || 'all'
    };
  }

  componentDidMount() {
    this.props.clearResponseMessage();
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.state.selectedChannel !== newProps.match.params.channelSlug ||
      this.state.selectedSubject !== newProps.match.params.subjectSlug
    ) {
      this.setState(
        { selectedSubject: newProps.match.params.subjectSlug, selectedChannel: newProps.match.params.channelSlug },
        () => {
          this.loadData();
        }
      );
    }
  }

  /**
   * @desc load step
   */
  navigateTopage = (subjectSlug, channelSlug) => {
    if (channelSlug || subjectSlug) {
      if (subjectSlug && channelSlug) this.props.history.push('/events/' + subjectSlug + '/' + channelSlug);
      else if (subjectSlug && !channelSlug) this.props.history.push('/events/' + subjectSlug + '/all');
      else if (!subjectSlug && channelSlug) this.props.history.push(`/events/all/${channelSlug}`);
      else this.props.history.push(`/`);
    }
  };

  loadData = async (isRefresh) => {
    let { eventList } = this.props.home;
    if (!eventList || isRefresh) eventList = await this.props.getEventList();
    if (eventList) {
      const { channels, subjects } = this.props.misc;
      const { selectedSubject, selectedChannel } = this.state;
      let subject = { id: TYPE_ALL },
        channel = { id: TYPE_ALL };
      if (subjects) {
        subject = subjects.find((x) => x.slug === selectedSubject);
      }
      if (channels) {
        channel = channels.find((x) => x.slug === selectedChannel);
      }
      const list = this.filterBySubjectAndChannel(channel ? channel.id : TYPE_ALL, subject ? subject.id : TYPE_ALL);
      const { isAuthenticated } = this.props.auth;
      // if user authorized, get watchList
      if (isAuthenticated) {
        let { watchList } = this.props.watchList;
        if (!watchList) watchList = await this.props.getUserWatchList();
        const watchListEvents = [];
        if (watchList) {
          // get event item from event list based on watch list event id
          list.forEach((item) => {
            const watchListIndex = watchList.findIndex((x) => x.eventId === item.id);
            if (watchListIndex !== -1) {
              item.isWatchlist = true;
              watchListEvents.push(item);
            } else {
              item.isWatchlist = false;
            }
          });
        }
        this.props.setWatchListEvents(watchListEvents);
      }
      this.setState({ filteredList: list });
    }
  };

  onSubjectChange = async (subject = { id: TYPE_ALL, name: 'All', slug: 'all' }) => {
    const { selectedChannel } = this.state;
    this.navigateTopage(subject.slug, selectedChannel);
  };

  onChannelChange = async (channel = { id: TYPE_ALL, name: 'All', slug: 'all' }) => {
    const { selectedSubject } = this.state;
    this.navigateTopage(selectedSubject, channel.slug);
  };

  filterBySubjectAndChannel = (channelId, subjectId) => {
    const { eventList } = this.props.home;
    let list = [...eventList];
    if ((channelId === TYPE_ALL || !channelId) && (subjectId === TYPE_ALL || !subjectId)) {
      list = [...eventList];
    } else {
      if (channelId && channelId !== TYPE_ALL) list = list.filter((x) => x.channelId === channelId);
      if (subjectId && subjectId !== TYPE_ALL) list = list.filter((x) => x.subjectId === subjectId);
    }
    return list;
  };

  onSearch = (searchText) => {
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      await this.onSubjectChange();
      await this.onChannelChange();
      this.filterEvents(searchText);
    }, 1000);
  };

  filterEvents = (searchText) => {
    const { eventList } = this.props.home;
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    if (!isEmpty(searchText)) {
      newList = eventList.filter(
        (x) =>
          (x.eventName && x.eventName.trim().toLowerCase().includes(searchText)) ||
          (x.subject && x.subject.trim().toLowerCase().includes(searchText))
      );
    } else {
      newList = eventList;
    }
    this.setState({ filteredList: newList });
  };

  onItemClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/event-detail/${id}`);
  };

  onPublisherNameClicked = (id) => {
    if (!id) return;
    this.props.history.push(`/publisher-detail/${id}`);
  };

  onEventAdded = () => {
    this.loadData();
  };

  onToggleWatchList = async (item) => {
    if (!item) return;

    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.props.history.push(`signup`);
      return;
    }
    item.isWatchlist = !item.isWatchlist;
    const result = await this.props.onToggleWatchListEvent(item.id);
    if (result) {
      this.loadData();
    }
  };

  render() {
    const loading_home = this.props.home.loading;
    const loading_watchList = this.props.watchList.loading;
    const loading = loading_home || loading_watchList;

    const { eventList } = this.props.home;
    const { filteredList, selectedChannel, selectedSubject } = this.state;
    const { subjects, eventTypes } = this.props.misc;

    if (loading && !eventList) {
      return <Loading />;
    }
    return (
      <>
        <Header
          subjectSlug={selectedSubject}
          channelSlug={selectedChannel}
          onSubjectChange={this.onSubjectChange}
          onEventTypeChange={this.onChannelChange}
          onSearchChange={this.onSearch}
          history={this.props.history}
          onEventAdded={this.onEventAdded}
        />
        <Container>
          <Row>
            <Contentbox>
              <H1style>Powow</H1style>
              <DisplaySubtext>
                <SubTitle>Connect to a world of live events from the comfort of your own home.</SubTitle>
                {/* <FilterButton>
                  <SvgIcon width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.3334 1.5H1.66669L8.33335 9.38333V14.8333L11.6667 16.5V9.38333L18.3334 1.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </SvgIcon>
                  Filter
                </FilterButton> */}
              </DisplaySubtext>
              <ButtonWrapper>
                {subjects &&
                  subjects.length > 0 &&
                  subjects.map((sub) => {
                    return (
                      <SubjectListItem
                        isSelected={sub.slug === selectedSubject}
                        key={sub.id}
                        item={sub}
                        onSubjectChange={() => {
                          this.onSubjectChange(sub);
                        }}
                      />
                    );
                  })}
              </ButtonWrapper>
              <Boxwrapper>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item) => {
                    return (
                      <ListItem
                        key={item.id}
                        item={item}
                        eventTypes={eventTypes}
                        isWatchListItem={item.isWatchlist}
                        onItemClicked={() => {
                          this.onItemClicked(item.id);
                        }}
                        onWatchListIconClicked={() => {
                          this.onToggleWatchList(item);
                        }}
                        onPublisherNameClicked={this.onPublisherNameClicked}
                      />
                    );
                  })}
              </Boxwrapper>
            </Contentbox>
          </Row>
        </Container>

        <Footer
          subjectSlug={selectedSubject}
          channelSlug={selectedChannel}
          onSubjectChange={this.onSubjectChange}
          history={this.props.history}
        />
      </>
    );
  }
}

Home.propTypes = {
  message: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  watchList: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  home: state.home,
  watchList: state.watchList,
  auth: state.auth,
  misc: state.misc
});

export default connect(mapStateToProps, {
  getEventList,
  createEvent,
  onToggleWatchListEvent,
  getUserWatchList,
  setWatchListEvents,
  clearResponseMessage,
  setErrorMessage,
  uploadImage,
  fetchIPLocation
})(Home);
