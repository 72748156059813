import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setHomeLoader, setEventList, setEventDetail } from '../actions/homeActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError, isEmail, isEmpty, validateUrl } from '../global/Helpers';

/**
 * @desc get event list
 */
export const getEventList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setHomeLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/event`);
    const { data } = response.data;
    dispatch(setEventList(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch event list', dispatch);
    return false;
  } finally {
    dispatch(setHomeLoader(false));
  }
};

/**
 * @desc Create new event
 * @param {*} obj event obj
 */
export const createEvent = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchError('Publisher is Required', dispatch);
      return;
    } else if (isEmpty(obj.publisher)) {
      dispatchError('Publisher is Required', dispatch);
      return;
    } else if (isEmpty(obj.name)) {
      dispatchError('Name is Required', dispatch);
      return;
    } else if (isEmpty(obj.email)) {
      dispatchError('Email is Required', dispatch);
      return;
    } else if (obj.email && !isEmail(obj.email)) {
      dispatchError('Email is not Valid', dispatch);
      return;
    } else if (!obj.startTime) {
      dispatchError('Start time is Required', dispatch);
      return;
    } else if (!obj.endTime) {
      dispatchError('End time is Required', dispatch);
      return;
    } else if (isEmpty(obj.eventName)) {
      dispatchError('Event name is Required', dispatch);
      return;
    } else if (isEmpty(obj.eventUrl)) {
      dispatchError('Event url is Required', dispatch);
      return;
    } else if (!validateUrl(obj.eventUrl)) {
      dispatchError('Event url is invalid', dispatch);
      return;
    } else if (isEmpty(obj.subjectId)) {
      dispatchError('Subject is Required', dispatch);
      return;
    } else if (isEmpty(obj.channelId)) {
      dispatchError('Channel is Required', dispatch);
      return;
    } else if (isEmpty(obj.eventTypeId)) {
      dispatchError('Event type is Required', dispatch);
      return;
    } else if (isEmpty(obj.country)) {
      dispatchError('Country is Required', dispatch);
      return;
    } else if (isEmpty(obj.language)) {
      dispatchError('Language is Required', dispatch);
      return;
    } else if (isEmpty(obj.publisherThumbnail)) {
      dispatchError('Publisher Thumbnail is Required', dispatch);
      return;
    }
    dispatch(setHomeLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/event`, obj);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to create event', dispatch);
    return false;
  } finally {
    dispatch(setHomeLoader(false));
  }
};

/**
 * @desc get event Item
 */
export const getEventItem = (id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setHomeLoader(true));

    if (!id) return;

    const response = await axios.get(`${REACT_APP_APIURL}/event/${id}`);
    const { data } = response.data;
    dispatch(setEventDetail(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch event item', dispatch);
    return false;
  } finally {
    dispatch(setHomeLoader(false));
  }
};

export const checkEventLiveStatus = (event) => {
  if (!event) return;

  if (event && event.startTime && event.endTime) {
    let currentTime = new Date().getTime();
    let startTime = new Date(event.startTime).getTime();
    let endTime = new Date(event.endTime).getTime();
    if (currentTime > startTime && currentTime < endTime)
      return true;
    else return false;
  }
}

/**
 * @desc  Update Image
 * @param {*} obj image file
 */
export const uploadImage = obj => async dispatch => {
  try {
    dispatch(clearResponseMessage());

    const response = await axios.post(`${REACT_APP_APIURL}/media/upload`, obj);
    const { data } = response;
    return data.data;
  } catch (e) {
    dispatchError(
      getAPIResponseError(e, dispatch) || 'Something went wrong, please try again later',
      dispatch,
    );
    return undefined;
  } finally {
  }
};

/**
 * @desc  fetch Ip Location
 * @param {*} 
 */
export const fetchIPLocation = () => async dispatch => {
  try {
    dispatch(clearResponseMessage());

    const response = await axios.get('https://extreme-ip-lookup.com/json/');
    const { data } = response;
    return data;
  } catch (e) {
    dispatchError(
      getAPIResponseError(e, dispatch) || 'Something went wrong, please try again later',
      dispatch,
    );
    return undefined;
  } finally {
  }
};


function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
