import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Logo from '../Logo/Logo';
import styled, { css } from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import Button from '../Button/Button';
import globalStyle from '../../assets/universal/globalStyles';
import { ReactComponent as Darrow } from '../../assets/images/15.svg';
import { isEmpty } from '../../global/Helpers';
import { logout } from '../../services/authService';
import Gravatar from 'react-gravatar';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import AddEventModal from '../../pages/Home/AddEventModal';

const Container = globalStyle.Container;
const Row = globalStyle.Row;
const Flexbox = globalStyle.Flexbox;
const HeaderBox = styled.header`
  align-items: center;
  padding: 16px 16px;
  @media only screen and (max-width: 1023px) {
    padding: 16px 0px;
  }
`;
const MenuUL = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  .k-button {
    background: transparent;
    border: 0;
    padding: 0 10px 0 0;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    :focus {
      color: #fff;
    }
    :focus.Darrow {
      color: red;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    display: block;
  }
  @media only screen and (max-width: 1023px) {
    margin-left: 0px;
    display: block;
  }
`;
const DownArrow = styled(Darrow)`
  margin-left: 4px;
  position: absolute;
  top: 50%;
  right: -10px;
  @media only screen and (max-width: 1023px) {
    right: 10px;
  }
`;
const MenuItem = styled.li`
  margin-right: 24px;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    margin-right: 0px;
    padding: 10px 0px;
  }
  @media only screen and (max-width: 1023px) {
    margin-right: 0px;
    padding: 10px 0px;
  }
  &:focus {
    color: #fff;
  }
`;
const SearchIcon = styled.svg`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #ffffff;
`;
const Searchbox = styled.div`
  position: relative;
  margin-right: 24px;
  margin-left: auto;
  @media only screen and (max-width: 767px) {
    margin: 10px 0px;
  }
  @media only screen and (max-width: 1023px) {
    margin: 10px 0px;
  }
`;
const SearchInput = styled.input`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: transparent;
  border: 0;
  border-radius: 6px;
  padding: 12px 15px 12px 50px;
  max-width: 120px;
  width: 100%;
  transition: 0.2s all ease;
  &.ich_active_input {
    background: #1e2028;
    max-width: 250px;
    color: rgba(255, 255, 255, 0.5);
  }
  &::-webkit-input-placeholder {
    color: #ffffff;
  }
  &:focus {
    transition: 0.2s all ease;
    background: #1e2028;
    max-width: 250px;
    color: rgba(255, 255, 255, 0.5);
    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &:focus ~ .SearchIcon {
    color: rgba(255, 255, 255, 0.5);
  }
  @media only screen and (max-width: 767px) {
    background: #1e2028;
    width: 100%;
    max-width: 100%;
    &:focus {
      max-width: unset;
    }
  }
  @media only screen and (max-width: 1023px) {
    background: #1e2028;
    width: 100%;
    max-width: 100%;
    &:focus {
      max-width: unset;
    }
  }
`;
const ButtonSign = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: 0;
  outline: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    width: 50%;
  }
`;

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  z-index: 1;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: #fff;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const MobileView = styled.div`
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    background-color: #000;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.8);
    padding: 16px;
    z-index: 10;
  }
  @media only screen and (max-width: 1023px) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    background-color: #000;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.8);
    padding: 16px;
    z-index: 10;
  }
`;

const Overlap = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const DesktopView = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 0;
  align-items: center;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;
const SubMenuUl = styled.ul`
  background: #1e2028;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px;
  &.submenuul {
    margin-top: 5px;
  }
`;
const MenuLi = styled.li`
  padding: 4px 10px;
  cursor: pointer;
  :hover {
    background: #2a2b34;
    border-radius: 5px;
  }
`;
const AvtarBox = styled.div`
  width: 38px;
  height: 38px;
  margin-left: 20px;
  @media only screen and (max-width: 767px) {
    margin-top: 20px;
  }
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
  @media only screen and (max-width: 1023px) {
    margin-top: 20px;
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      isMobileView: false,
      isAddEventModal: false
    };
  }

  componentDidMount() { }

  /**
   * @desc load step
   */
  navigateTopage = (subjectSlug, channelSlug) => {
    if (channelSlug || subjectSlug) {
      if (subjectSlug && channelSlug) this.props.history.push('/events/' + subjectSlug + '/' + channelSlug);
      else if (subjectSlug && !channelSlug) this.props.history.push('/events/' + subjectSlug + '/all');
      else if (!subjectSlug && channelSlug) this.props.history.push(`/events/all/${channelSlug}`);
      else this.props.history.push(`/`);
    }
  };

  onSubjectChange = (item) => {
    this.closeMenu();
    this.navigateTopage(item.slug, this.props.channelSlug);
  };

  onEventTypeChange = (item) => {
    this.closeMenu();
    this.navigateTopage(this.props.subjectSlug, item.slug);
  };

  onSearchChange = (e) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    if (this.props.onSearchChange) this.props.onSearchChange(searchText);
  };

  onSignUpClick = () => {
    this.props.history.push('/signup');
  };

  onSignInClick = () => {
    this.props.history.push('/login');
  };

  onLogout = async () => {
    await this.props.logout();
    this.props.history.push('/home');
  };

  onEventAdded = (params) => {
    this.setState({ isAddEventModal: false });
    if (this.props.onEventAdded) this.props.onEventAdded(params);
  };

  onWatchListClick = () => {
    this.props.history.push('/watchList');
  };

  onProfileClick = () => {
    this.props.history.push('/profile');
  };

  ToggleMobileView = () => {
    if (!this.state.isMobileView) document.body.classList.add('menuopen');
    else document.body.classList.remove('menuopen');
    this.setState({ isMobileView: !this.state.isMobileView });
  };

  closeMenu = () => {
    document.body.classList.remove('menuopen');
    this.setState({ isMobileView: false });
  };

  onToggleAddEventModal = () => {
    this.closeMenu();
    this.setState({ isAddEventModal: !this.state.isAddEventModal });
  };

  render() {
    const { subjectSlug, channelSlug } = this.props;
    const { subjects, channels } = this.props.misc;
    const { searchText, isMobileView, isAddEventModal } = this.state;
    const { user } = this.props.auth;
    return (
      <>
        <HeaderBox>
          <Container>
            <Row>
              <Flexbox>
                <Logo size={1} />

                {isMobileView ? (
                  <MobileView>
                    <MenuUL>
                      <MenuItem>
                        <Popup
                          className='Subjects'
                          trigger={
                            <div>
                              <span>Subjects</span> <DownArrow className='Down' />
                            </div>
                          }
                          position='bottom left'
                          closeOnDocumentClick
                          mouseLeaveDelay={300}
                          mouseEnterDelay={0}
                          arrow={false}>
                          {(close) => (
                            <SubMenuUl>
                              {subjects &&
                                subjects.length > 0 &&
                                subjects.map((item) => {
                                  return (
                                    <MenuLi
                                      key={item.id}
                                      className={classNames({ selected: item.slug === subjectSlug })}
                                      onClick={() => {
                                        close();
                                        this.onSubjectChange(item);
                                      }}>
                                      {item.name}
                                    </MenuLi>
                                  );
                                })}
                            </SubMenuUl>
                          )}
                        </Popup>
                      </MenuItem>
                      {/* <MenuItem>
                        <Popup
                          className='Channels'
                          trigger={
                            <div>
                              <span>Channels</span> <DownArrow className='Down' />
                            </div>
                          }
                          position='bottom left'
                          closeOnDocumentClick
                          mouseLeaveDelay={300}
                          mouseEnterDelay={0}
                          arrow={false}>
                          {(close) => (
                            <SubMenuUl>
                              {channels &&
                                channels.length > 0 &&
                                channels.map((item) => {
                                  return (
                                    <MenuLi
                                      key={item.id}
                                      className={classNames({ selected: item.slug === channelSlug })}
                                      onClick={() => {
                                        close();
                                        this.onEventTypeChange(item);
                                      }}>
                                      {item.name}
                                    </MenuLi>
                                  );
                                })}
                            </SubMenuUl>
                          )}
                        </Popup>
                      </MenuItem> */}
                      {/* <MenuItem>
                        <Popup trigger={<span>Curators</span>}
                          position="bottom right"
                          closeOnDocumentClick
                          mouseLeaveDelay={300}
                          mouseEnterDelay={0}
                          arrow={false}>
                          {(close) => (
                            <>
                            </>
                          )}
                        </Popup>
                      </MenuItem> */}
                    </MenuUL>
                    <Searchbox>
                      <SearchInput
                        type='text'
                        placeholder='Search'
                        name='search'
                        value={searchText}
                        onChange={this.onSearchChange}
                      />
                      <SearchIcon
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M21 20.9999L16.65 16.6499'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </SearchIcon>
                    </Searchbox>
                    {isEmpty(user) ? (
                      <>
                        <ButtonSign type='button' onClick={this.onSignUpClick}>
                          Sign Up
                        </ButtonSign>
                        <ButtonSign type='button' onClick={this.onSignInClick}>
                          Sign In
                        </ButtonSign>
                      </>
                    ) : (
                        <>
                          <Button type='button' title='Add Event' isPrimary={true} onClick={this.onToggleAddEventModal} />
                          <Popup
                            className='Avatar'
                            trigger={
                              <AvtarBox>
                                {user.profile_url ? (
                                  <img src={user.profile_url} alt='logo' />
                                ) : (
                                    <Gravatar email={user.email} default={'wavatar'} rating={'pg'} />
                                  )}
                              </AvtarBox>
                            }
                            position='bottom left'
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            arrow={false}>
                            {(close) => (
                              <SubMenuUl>
                                <MenuLi onClick={this.onWatchListClick}>
                                  <span>WatchList</span>
                                </MenuLi>
                                <MenuLi onClick={this.onLogout}>
                                  <span>LogOut</span>
                                </MenuLi>
                              </SubMenuUl>
                            )}
                          </Popup>
                        </>
                      )}
                    <Overlap onClick={() => this.ToggleMobileView(true)}></Overlap>
                  </MobileView>
                ) : (
                    <DesktopView>
                      <MenuUL>
                        <MenuItem>
                          <Popup
                            className='Subjects'
                            trigger={
                              <div>
                                <span>Subjects</span> <DownArrow className='Down' />
                              </div>
                            }
                            position='bottom left'
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            arrow={false}>
                            {(close) => (
                              <SubMenuUl>
                                {subjects &&
                                  subjects.length > 0 &&
                                  subjects.map((item) => {
                                    return (
                                      <MenuLi
                                        key={item.id}
                                        className={classNames({ selected: item.slug === subjectSlug })}
                                        onClick={() => {
                                          close();
                                          this.onSubjectChange(item);
                                        }}>
                                        {item.name}
                                      </MenuLi>
                                    );
                                  })}
                              </SubMenuUl>
                            )}
                          </Popup>
                        </MenuItem>
                        {/* <MenuItem>
                          <Popup
                            className='Channels'
                            trigger={
                              <div>
                                <span>Channels</span> <DownArrow className='Down' />
                              </div>
                            }
                            position='bottom left'
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            arrow={false}>
                            {(close) => (
                              <SubMenuUl>
                                {channels &&
                                  channels.length > 0 &&
                                  channels.map((item) => {
                                    return (
                                      <MenuLi
                                        key={item.id}
                                        className={classNames({ selected: item.slug === channelSlug })}
                                        onClick={() => {
                                          close();
                                          this.onEventTypeChange(item);
                                        }}>
                                        {item.name}
                                      </MenuLi>
                                    );
                                  })}
                              </SubMenuUl>
                            )}
                          </Popup>
                        </MenuItem> */}
                        {/* <MenuItem>
                          <Popup trigger={<span>Curators</span>}
                            position="bottom right"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            arrow={false}>
                            {(close) => (
                              <>
                              </>
                            )}
                          </Popup>
                        </MenuItem> */}
                      </MenuUL>
                      <Searchbox>
                        <SearchInput
                          type='text'
                          placeholder='Search'
                          name='search'
                          value={searchText}
                          onChange={this.onSearchChange}
                        />
                        <SearchIcon
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 20.9999L16.65 16.6499'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </SearchIcon>
                      </Searchbox>
                      {isEmpty(user) ? (
                        <>
                          <ButtonSign type='button' onClick={this.onSignUpClick}>
                            Sign Up
                        </ButtonSign>
                          <ButtonSign first type='button' onClick={this.onSignInClick}>
                            Sign In
                        </ButtonSign>
                        </>
                      ) : (
                          <>
                            <Button type='button' title='Add Event' isPrimary={true} onClick={this.onToggleAddEventModal} />
                            <Popup
                              className='Avatar'
                              trigger={
                                <AvtarBox>
                                  {user.profile_url ? (
                                    <img src={user.profile_url} alt='logo' />
                                  ) : (
                                      <Gravatar email={user.email} default={'wavatar'} rating={'pg'} />
                                    )}
                                </AvtarBox>
                              }
                              position='bottom right'
                              closeOnDocumentClick
                              mouseLeaveDelay={300}
                              mouseEnterDelay={0}
                              arrow={false}>
                              {(close) => (
                                <SubMenuUl className='submenuul'>
                                  <MenuLi onClick={this.onWatchListClick}>
                                    <span>WatchList</span>
                                  </MenuLi>
                                  <MenuLi onClick={this.onProfileClick}>
                                    <span>Profile</span>
                                  </MenuLi>
                                  <MenuLi onClick={this.onLogout}>
                                    <span>LogOut</span>
                                  </MenuLi>
                                </SubMenuUl>
                              )}
                            </Popup>
                          </>
                        )}
                    </DesktopView>
                  )}

                <StyledBurger onClick={() => this.ToggleMobileView(true)}>
                  <div />
                  <div />
                  <div />
                </StyledBurger>
              </Flexbox>
            </Row>
          </Container>
        </HeaderBox>

        {isAddEventModal && (
          <Dialog
            className='dialogBox primary-DialogBox'
            width={600}
            title='Add Event'
            onClose={this.onToggleAddEventModal}>
            <AddEventModal onClose={this.onToggleAddEventModal} {...this.props} onEventAdded={this.onEventAdded} />
          </Dialog>
        )}
      </>
    );
  }
}

Header.propTypes = {
  message: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth,
  misc: state.misc
});

export default connect(mapStateToProps, {
  logout
})(Header);
