import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.svg';

const AuthBox = styled.div`
  display: flex;
  align-items: stretch;
    height: 100%;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
const LogoBoxWrapper = styled.div`
  background-color: #1e2028;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
    background-color: transparent;
  }
`;
const Logobox = styled.div`
  text-align: center;
  max-width: 63%;
  @media only screen and (max-width: 1023px) {
    padding: 20px 20px 0 20px;
    max-width: unset;
  }
  .logo {
    margin-bottom: 50px;
    @media only screen and (max-width: 1023px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 767px) {
      width: 40%;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    display: block;
    @media only screen and (max-width: 1023px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .detailspan {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 15px;
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthBoxWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  @media only screen and (max-width: 767px) {
    width: auto;
  }
`;
const ContainerSm = styled.div`
  max-width: 480px;
  width: 100%;
  margin: auto;
  padding: 0 45px;
  display: flex;
  @media only screen and (max-width: 1023px) {
    padding: 0 20px;
  }
`;

export class AuthLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <AuthBox>
        <LogoBoxWrapper>
          <Logobox>
            <img src={Logo} alt='' className='logo' />
            <span>Welcome!</span>
            <span className='detailspan'>Connect to a world of live events from the comfort of your own home.</span>
          </Logobox>
        </LogoBoxWrapper>
        <AuthBoxWrapper>
          <ContainerSm>{children}</ContainerSm>
        </AuthBoxWrapper>
      </AuthBox>
    );
  }
}

export default AuthLayout;
