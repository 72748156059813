import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { clearResponseMessage } from '../../../actions/messageActions';
import Status from '../../../components/StatusBox/Status';
import { getUrlParam, isEmpty } from '../../../global/Helpers';
import { logout, resetPassword } from '../../../services/authService';
import AuthLayout from '../AuthLayout';
import classNames from 'classnames';

const AuthBoxDetailWrapper = styled.div`
  width: 100%;
  display: block;
  p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      color: #fe715b;
      cursor: pointer;
    }
  }
`;
const AuthBoxDetail = styled.div`
  text-align: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
  }
  .h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: -1.08px;
    color: #ffffff;
    margin-bottom: 5px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const AuthForm = styled.form`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const FormColFull = styled.div`
  display: block;
  width: 100%;
`;
const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 0;
  padding: 14px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  ::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.3);
  }
`;
const ContentHead = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  margin-top: 20px;
  ${(props) =>
    props.first &&
    css`
      margin-top: 0px;
    `}
`;
const ButtonBox = styled.div`
  text-align: center;
  margin-bottom: 60px;
  margin-top: 40px;
  @media only screen and (max-width: 1365px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;
const Button = styled.button`
  background: #ff715b;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.01);
  border: 0;
  border-radius: 4px;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: ''
    };
  }

  componentDidMount() {
    const mode = getUrlParam(this.props.location.search, 'mode');
    if (mode !== 'resetPassword') {
      this.navigateToLogin();
      return;
    }
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleNewPasswordInputChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword });
  };

  handleConfirmPasswordInputChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState({ confirmPassword });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const obj = {
      password: newPassword,
      confirmPassword: confirmPassword,
      token: getUrlParam(this.props.location.search, 'oobCode')
    };
    const result = await this.props.resetPassword(obj);
    if (result === true) {
      this.props.logout();
    }
  };

  onClickLogin = () => {
    this.navigateToLogin();
  };

  navigateToLogin = () => {
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Status text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Status text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { newPassword, confirmPassword } = this.state;
    const { resSuccess } = this.props.message;

    return (
      <>
        <AuthLayout>
          <AuthBoxDetailWrapper>
            <AuthBoxDetail>
              <h2 className='h2'>Reset Password</h2>
              <span>Enter your new password for your account.</span>
            </AuthBoxDetail>
            {isEmpty(resSuccess) && (
              <AuthForm onSubmit={this.onSubmit}>
                <FormColFull>
                  <ContentHead first>New Password</ContentHead>
                  <Input
                    type='password'
                    placeholder='enter password'
                    autocomplete='off'
                    maxLength='256'
                    name='password'
                    required=''
                    value={newPassword}
                    onChange={this.handleNewPasswordInputChange}
                  />
                </FormColFull>
                <FormColFull>
                  <ContentHead>Confirm Password</ContentHead>
                  <Input
                    type='password'
                    placeholder='enter confirm password'
                    autocomplete='off'
                    maxLength='256'
                    name='confirmPassword'
                    required=''
                    value={confirmPassword}
                    onChange={this.handleConfirmPasswordInputChange}
                  />
                </FormColFull>
                {this.renderErrors()}
                <ButtonBox  className={classNames("btn-save-loading", { 'loading': loading })}>
                  <Button type='submit' disabled={loading} className="btn base_btn">
                    Reset Password
                  </Button>
                </ButtonBox>
              </AuthForm>
            )}
            {this.renderSuccess()}
            <p>
              Go back to{' '}
              <a disabled={loading} onClick={this.onClickLogin}>
                Log In
              </a>
            </p>
          </AuthBoxDetailWrapper>
        </AuthLayout>
      </>
    );
  }
}
ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  resetPassword,
  logout
})(ResetPassword);
