export const PROJECT_ID = 'powow-a9d95';
export const API_URL = `https://us-central1-${PROJECT_ID}.cloudfunctions.net/api`;
export const SEGMENT_KEY = '';

export const EVENT_START_TIME = 'startTime';
export const EVENT_END_TIME = 'endTime';
export const TYPE_ALL = 'all';
export const SUBJECT = 'subject';
export const CHANNEL = 'channel';
export const EVENT_TYPE = 'eventType';
export const LOGOUT = 'Logout';
export const WATCHLIST = 'Watchlist';

export const TYPE_TERMS = 'terms';
export const TYPE_PRIVACY = 'privacy';

export const firebaseConfig = {
    // Your web app's Firebase configuration
    apiKey: "AIzaSyA17CuWs8rUxtbijmY2C1AblaZ5JLon8WI",
    authDomain: "powow-a9d95.firebaseapp.com",
    databaseURL: "https://powow-a9d95.firebaseio.com",
    projectId: "powow-a9d95",
    storageBucket: "powow-a9d95.appspot.com",
    messagingSenderId: "447478260941",
    appId: "1:447478260941:web:d196ec78ed59dc5eb3b584",
    measurementId: "G-FVZM52WY77"
};